@font-face {
  font-family: 'circular-nova-black';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/circular/CircularStd-Black.woff") format('woff'), url("../fonts/circular/CircularStd-Black.ttf") format('ttf');
}
@font-face {
  font-family: 'circular-nova-black-italic';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/circular/CircularStd-BlackItalic.woff") format('woff'), url("../fonts/circular/CircularStd-BlackItalic.ttf") format('truetype');
}
@font-face {
  font-family: 'circular-nova-bold';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/circular/CircularStd-Bold.woff") format('woff'), url("../fonts/circular/CircularStd-Bold.ttf") format('ttf');
}
@font-face {
  font-family: 'circular-nova-bold-italic';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/circular/CircularStd-BoldItalic.woff") format('woff'), url("../fonts/circular/CircularStd-BoldItalic.ttf") format('truetype');
}
@font-face {
  font-family: 'circular-nova-medium';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/circular/CircularStd-Medium.woff") format('woff'), url("../fonts/circular/CircularStd-Medium.ttf") format('ttf');
}
@font-face {
  font-family: 'circular-nova-medium-italic';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/circular/CircularStd-MediumItalic.woff") format('woff'), url("../fonts/circular/CircularStd-MediumItalic.ttf") format('truetype');
}
@font-face {
  font-family: 'circular-nova-regular';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/circular/CircularStd-Regular.woff") format('woff'), url("../fonts/circular/CircularStd-Regular.ttf") format('ttf');
}
@font-face {
  font-family: 'circular-nova-regular-italic';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/circular/CircularStd-RegularItalic.woff") format('woff'), url("../fonts/circular/CircularStd-RegularItalic.ttf") format('truetype');
}
@font-face {
  font-family: 'circular-nova-book';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/circular/CircularStd-Book.woff2") format('woff2');
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.owl-item {
  transform: translateZ(0);
}
img {
  display: block;
}
button {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
input,
textarea {
  font-family: 'circular-nova-regular', Sans-Serif;
  -webkit-appearance: none;
  border-radius: 0;
}
html,
body {
  font-size: 16px;
  font-weight: 400;
  background-color: #f7f7f7;
  font-family: 'circular-nova-regular', Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
html.overflow-hidden,
body.overflow-hidden {
  overflow: hidden;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
}
html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #4e4e50;
  border-radius: 10px;
}
* {
  margin: 0;
  padding: 0;
}
header,
footer,
section {
  max-width: 100vw;
  overflow-x: hidden;
}
.b-wrapper {
  width: 100%;
  display: table;
  height: 100%;
}
.content {
  display: table-row;
  width: 100%;
  height: 100%;
}
section {
  width: 100%;
  position: relative;
  z-index: 5;
}
.container {
  max-width: 1536px;
  padding-left: 96px;
  padding-right: 96px;
  width: 100%;
}
.container.container_small {
  max-width: 1344px;
}
.container .col {
  padding-left: 16px;
  padding-right: 16px;
}
@media only screen and (max-width: 1200px) {
  .container {
    padding-left: 48px;
    padding-right: 48px;
  }
  .container .row {
    margin-left: -16px;
    margin-right: -16px;
  }
}
@media only screen and (max-width: 600px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media only screen and (max-width: 359px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
section {
  margin-top: 88px;
  margin-bottom: 88px;
}
section.pt-0 .container {
  padding-top: 0 !important;
}
section.bg-light {
  background-color: #fff;
}
section.bg-grey {
  background-color: #f7f7f7;
}
@media only screen and (max-width: 991px) {
  section {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}
@media only screen and (max-width: 600px) {
  section {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}
.bg-light {
  background-color: #fff;
}
.bg-grey {
  background-color: #f7f7f7;
}
.section_padding {
  margin-top: 0;
  margin-bottom: 0;
}
.section_padding .container {
  padding-top: 88px;
  padding-bottom: 88px;
}
@media only screen and (max-width: 991px) {
  .section_padding .container {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
@media only screen and (max-width: 600px) {
  .section_padding .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.section_small {
  margin-top: 96px;
  margin-bottom: 96px;
}
@media only screen and (max-width: 991px) {
  .section_small {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}
.section_small_padding {
  margin-top: 0;
  margin-bottom: 0;
}
.section_small_padding .container {
  padding-top: 96px;
  padding-bottom: 96px;
}
@media only screen and (max-width: 991px) {
  .section_small_padding .container {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}
.section_big {
  margin-top: 192px;
  margin-bottom: 192px;
}
@media only screen and (max-width: 991px) {
  .section_big {
    margin-top: 96px;
    media: 96px;
  }
}
@media only screen and (max-width: 600px) {
  .section_big {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
.section_big_padding {
  margin-top: 0;
  margin-bottom: 0;
}
.section_big_padding .container {
  padding-top: 192px;
  padding-bottom: 192px;
}
@media only screen and (max-width: 991px) {
  .section_big_padding .container {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
@media only screen and (max-width: 600px) {
  .section_big_padding .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.section_left-grey {
  padding-bottom: 2px;
}
.section_left-grey .container {
  position: relative;
}
@media (min-width: 1024px) {
  .section_left-grey .container:before {
    position: absolute;
    content: '';
    display: block;
    height: calc(100% + 2px);
    width: 1500px;
    top: 0;
    left: -1470px;
    background-color: #f7f7f7;
    box-shadow: 0 0 12px 4px #f7f7f7;
    z-index: 1;
  }
  .section_left-grey .container:after {
    position: absolute;
    content: '';
    display: block;
    height: 100%;
    width: 1500px;
    top: 0;
    right: -1470px;
    background: #f7f7f7;
    box-shadow: 0 0 12px 4px #f7f7f7;
    z-index: 1;
  }
}
@media (min-width: 1201px) {
  .section_left-grey .container:before {
    left: -1430px;
  }
  .section_left-grey .container:after {
    right: -1500px;
    background: linear-gradient(to right, rgba(247,247,247,0) 0%, #f7f7f7 40%, #f7f7f7 100%);
    box-shadow: none;
  }
}
.row {
  margin-bottom: 0;
}
h1 {
  color: #111;
  font-family: 'circular-nova-black', Sans-Serif;
  font-size: 52px;
  letter-spacing: -1.2px;
  line-height: 64px;
  margin-bottom: 32px;
  margin-top: 0;
}
@media only screen and (max-width: 991px) {
  h1 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 24px;
  }
}
h2 {
  font-family: 'circular-nova-bold', Sans-Serif;
  color: #111;
  font-size: 40px;
  letter-spacing: -0.2px;
  line-height: 48px;
  margin-top: 0;
  margin-bottom: 48px;
}
@media only screen and (max-width: 991px) {
  h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 24px;
  }
}
h3 {
  color: #111;
  font-family: "circular-nova-medium", Sans-Serif;
  font-size: 28px;
  letter-spacing: -0.3px;
  line-height: 40px;
  margin-bottom: 24px;
  margin-top: 24px;
}
@media only screen and (max-width: 991px) {
  h3 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
}
h4,
.h4 {
  color: #111;
  font-family: "circular-nova-medium", Sans-Serif;
  font-size: 22px;
  letter-spacing: -0.3px;
  line-height: 32px;
  margin-bottom: 24px;
  margin-top: 0;
}
h4 a,
.h4 a {
  font-size: 22px;
  letter-spacing: -0.3px;
  line-height: 32px;
}
@media only screen and (max-width: 991px) {
  h4,
  .h4 {
    font-size: 18px;
    line-height: 24px;
  }
  h4 a,
  .h4 a {
    font-size: 18px;
    line-height: 24px;
  }
}
h6 {
  font-family: 'circular-nova-bold', Sans-Serif;
  color: #111;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 8px;
}
p {
  margin-bottom: 32px;
  color: #6e6e6e;
  font-size: 20px;
  letter-spacing: -0.27px;
  line-height: 32px;
}
@media only screen and (max-width: 991px) {
  p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
    margin-bottom: 24px;
  }
}
.s-header__breadcrumps {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 991px) {
  .s-header__breadcrumps {
    display: none;
  }
}
.s-header__breadcrumps a,
.s-header__breadcrumps span {
  color: #a0a0a0;
  font-family: "circular-nova-medium", Sans-Serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-right: 24px;
  transition: all 0.2s ease;
  position: relative;
}
.s-header__breadcrumps a:last-child,
.s-header__breadcrumps span:last-child {
  margin-right: 0;
  pointer-events: none;
}
.s-header__breadcrumps a:last-child:after,
.s-header__breadcrumps span:last-child:after {
  display: none;
}
.s-header__breadcrumps a:after,
.s-header__breadcrumps span:after {
  content: '/';
  display: block;
  color: #a0a0a0;
  font-family: "circular-nova-medium", Sans-Serif;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  position: absolute;
  right: -16px;
  top: 0;
}
.s-header__breadcrumps a:hover,
.s-header__breadcrumps span:hover,
.s-header__breadcrumps a.active,
.s-header__breadcrumps span.active {
  color: #111;
}
img {
  display: block;
}
strong {
  color: #111;
  font-family: "circular-nova-medium", Sans-Serif;
}
.learn-more__link:after {
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0px;
  transition: all 0.2s ease;
}
.learn-more__link:hover:after {
  width: 0;
}
a {
  color: #5a3cc5;
  outline: none;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
}
@media only screen and (max-width: 991px) {
  a {
    font-size: 18px;
    line-height: 26px;
  }
}
.learn-more__link {
  color: #5a3cc5;
  font-family: "circular-nova-medium", Sans-Serif;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  text-decoration: none;
  position: relative;
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .learn-more__link {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }
}
.btn__wrap {
  position: relative;
  z-index: 2;
  margin-bottom: 4px;
  margin-right: 4px;
  display: inline-flex;
  margin-top: 16px;
}
.btn__wrap a,
.btn__wrap button {
  display: inline-flex;
  padding: 20px 46px;
  transition: transform 0.2s ease;
}
@media only screen and (max-width: 767px) {
  .btn__wrap a,
  .btn__wrap button {
    padding: 20px 44px;
  }
}
@media only screen and (max-width: 400px) {
  .btn__wrap a,
  .btn__wrap button {
    width: 100%;
    max-width: inherit !important;
    min-width: 100%;
  }
}
.btn__wrap .btn__image {
  margin-left: 13px;
}
@media only screen and (max-width: 400px) {
  .btn__wrap {
    width: 100%;
    margin-right: 0 !important;
  }
}
.btn_purple {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5a3cc5 !important;
}
.btn_purple.gradient {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  padding: 12px 32px;
  background-color: transparent !important;
  border-radius: 16px;
}
.btn_purple.gradient::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 466px;
  background: linear-gradient(96.51deg, #4428bc -0.28%, #e00a6b 100%), linear-gradient(96.51deg, #eaf0ff -0.28%, #f0e9fd 100%);
  transform: translateX(0);
  transition: transform 0.2s ease-in;
}
.btn_purple.gradient:hover::after {
  transform: translateX(-170px);
}
.btn_bordered {
  border: 1px solid #5a3cc5 !important;
  background-color: #fff !important;
}
.btn_grey {
  margin-top: 16px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5a3cc5;
  padding: 20px 46px;
  transition: all 0.2s ease;
  margin-bottom: 4px;
}
@media only screen and (max-width: 767px) {
  .btn_grey {
    padding: 20px 44px;
  }
}
@media only screen and (max-width: 400px) {
  .btn_grey {
    width: 100%;
    max-width: inherit !important;
  }
}
.btn_grey:hover {
  background-color: #5a3cc5;
}
.btn_grey:hover span {
  color: #fff;
}
.btn__wrap:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ffab30;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: transform 0.2s ease;
}
.arrow-block_bottom {
  display: block;
  width: 10px;
  height: 10px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  margin-left: 16px;
  position: relative;
  top: -2px;
  transition: all 0.2s ease;
}
.btn__wrap:hover a,
.btn__wrap:hover button {
  transform: translateX(-4px) translateY(-4px);
}
.btn__wrap:hover:after {
  transform: translateX(4px) translateY(4px);
}
@media only screen and (max-width: 500px) {
  .btn__wrap:hover:after {
    display: none;
  }
}
.btn_purple span {
  color: #fff;
  font-family: "circular-nova-medium", Sans-Serif;
  font-size: 20px;
  line-height: 32px;
}
@media only screen and (max-width: 991px) {
  .btn_purple span {
    font-size: 18px;
    line-height: 24px;
  }
}
.btn_grey span {
  color: #5a3cc5;
  font-family: "circular-nova-medium", Sans-Serif;
  font-size: 20px;
  line-height: 32px;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 991px) {
  .btn_grey span {
    font-size: 18px;
    line-height: 24px;
  }
}
.btn__wrap.notavailable:hover {
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
}
.btn__wrap.notavailable:hover .btn_purple {
  cursor: default;
}
.btn__wrap.notavailable:hover:after {
  display: none;
}
.btn__wrap.notavailable .btn_purple {
  background-color: #e0dfe0 !important;
}
.btn__wrap.notavailable .available__img,
.btn__wrap.notavailable .available__text {
  display: none;
}
.btn__wrap.available .notavailable__img,
.btn__wrap.available .notavailable__text {
  display: none;
}
.donload-btn__wrap {
  display: inline-flex;
  position: relative;
}
.donload-btn__wrap:hover .donload-btn__tooltip {
  display: flex;
}
.donload-btn__wrap .donload-btn__tooltip {
  position: absolute;
  right: -156px;
  height: 48px;
  width: 140px;
  background-color: #6e6e6e;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 4;
  top: 12px;
}
@media only screen and (max-width: 1024px) {
  .donload-btn__wrap .donload-btn__tooltip {
    display: none !important;
  }
}
.donload-btn__wrap .donload-btn__tooltip span {
  color: #fff;
}
.donload-btn__wrap .donload-btn__tooltip:before {
  position: absolute;
  content: '';
  left: -6px;
  top: calc(50% - 6px);
  border-top: 6px solid transparent;
  border-right: 6px solid #6e6e6e;
  border-bottom: 6px solid transparent;
}
.donload-btn__wrap .download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 272px;
  max-height: 72px;
  padding: 16px;
  background-color: #f2f0fb;
  transition: all 0.2s ease;
}
.donload-btn__wrap .download-btn:hover {
  background-color: #5a3cc5;
}
.donload-btn__wrap .download-btn:hover span {
  color: #fff;
}
.donload-btn__wrap .download-btn:hover svg g {
  fill: #fff;
}
.donload-btn__wrap .download-btn svg {
  width: 20px;
  height: 18px;
}
.donload-btn__wrap .download-btn svg g {
  transition: all 0.2s ease;
}
.donload-btn__wrap .download-btn span {
  font-size: 16px;
  font-family: "circular-nova-medium", Sans-Serif;
  letter-spacing: -0.08px;
  line-height: 20px;
  margin-left: 16px;
  width: calc(100% - 36px);
  transition: all 0.2s ease;
}
.calc-btn__wrap {
  display: inline-flex;
  position: relative;
}
@media only screen and (max-width: 400px) {
  .calc-btn__wrap {
    width: 100%;
  }
}
.calc-btn__wrap .calc-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 272px;
  max-height: 72px;
  padding: 16px;
  background-color: #f2f0fb;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 767px) {
  .calc-btn__wrap .calc-btn {
    max-height: 64px;
  }
}
@media only screen and (max-width: 400px) {
  .calc-btn__wrap .calc-btn {
    width: 100%;
    max-width: inherit;
    max-height: inherit;
    min-height: 72px;
  }
}
.calc-btn__wrap .calc-btn:hover {
  background-color: #5a3cc5;
}
.calc-btn__wrap .calc-btn:hover span {
  color: #fff;
}
.calc-btn__wrap .calc-btn:hover svg path {
  fill: #5a3cc5;
}
.calc-btn__wrap .calc-btn:hover svg g {
  fill: #fff;
}
.calc-btn__wrap .calc-btn svg {
  width: 20px;
  height: 18px;
}
.calc-btn__wrap .calc-btn svg g {
  transition: all 0.2s ease;
}
.calc-btn__wrap .calc-btn span {
  font-size: 16px;
  font-family: "circular-nova-medium", Sans-Serif;
  letter-spacing: -0.08px;
  line-height: 20px;
  margin-left: 16px;
  width: calc(100% - 36px);
  transition: all 0.2s ease;
}
.calc-btn__wrap .calc-btn svg path,
.calc-btn__wrap .calc-btn svg g {
  transition: all 0.2s ease;
}
.form_custom [type="checkbox"]:not(:checked)+span:not(.lever):after {
  transform: none !important;
  margin-top: 0 !important;
}
.form_custom [type="checkbox"]:not(:checked)+span:not(.lever):before {
  background-color: transparent !important;
  margin-top: 0 !important;
}
.form_custom [type="checkbox"]:checked+span:not(.lever):after {
  background-color: #111;
  border: 2px solid #111 !important;
  transform: none !important;
  margin-top: 0 !important;
}
.form_custom [type="checkbox"]:checked+span:not(.lever):before {
  width: 8px;
  height: 13px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 2;
}
.form_custom .checkbox-wrap {
  margin: 8px 0 40px;
  position: relative;
}
.form_custom .checkbox-wrap .form-error {
  top: auto;
  bottom: -16px;
}
.form_custom .checkbox-wrap [type="checkbox"].filled-in.invalid+span:not(.lever):after {
  border: 2px solid #d51716 !important;
}
.form_custom .checkbox-wrap [type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid #111 !important;
  background-color: #111;
}
.form_custom .checkbox-wrap [type="checkbox"].filled-in:checked+span:not(.lever) {
  color: #111;
}
.form_custom .checkbox-wrap span {
  color: #a0a0a0;
  font-size: 18px;
  letter-spacing: 0.4px;
  line-height: 24px;
  height: auto;
  padding-left: 40px;
}
.form_custom .checkbox-wrap span a {
  font-size: 18px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.form_custom .checkbox-wrap span:before {
  top: 1px !important;
  left: 3px !important;
}
.form_custom .checkbox-wrap span:after {
  border: 2px solid #e0dfe0 !important;
  border-radius: 0 !important;
  height: 24px !important;
  width: 24px !important;
}
.form_custom .textarea-wrap .input-field {
  margin-bottom: 40px;
}
@media only screen and (max-width: 600px) {
  .form_custom .textarea-wrap .input-field {
    margin-bottom: 24px;
  }
}
.form_custom .input-wrap {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .form_custom .input-wrap {
    flex-wrap: wrap;
  }
}
.form_custom .input-wrap .input-field {
  width: 100%;
}
.form_custom .input-wrap .input-field_half {
  width: 48%;
}
@media only screen and (max-width: 600px) {
  .form_custom .input-wrap .input-field_half {
    width: 100%;
  }
}
.form_custom .input-field {
  margin-top: 0;
  margin-bottom: 40px;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .form_custom .input-field {
    margin-bottom: 32px;
  }
}
.form_custom .input-field label {
  color: #111;
  font-family: 'circular-nova-bold', Sans-Serif;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-transform: uppercase;
  transform: translateY(0) scale(1) !important;
  position: relative;
  left: 0;
}
.form_custom .input-field .select-dropdown.dropdown-content li.disabled {
  display: none;
}
.form_custom .input-field .select-wrapper {
  position: static;
}
.form_custom .input-field .select-wrapper .caret {
  display: none;
}
.form_custom .input-field .select-wrapper:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-left: 2px solid #111;
  border-bottom: 2px solid #111;
  transform: rotate(-45deg);
  position: absolute;
  right: 20px;
  bottom: 28px;
}
.form_custom .input-field .select-wrapper input {
  color: rgba(17,17,17,0.3);
  position: relative;
  box-sizing: border-box;
  padding-right: 40px;
  height: 50px;
}
@media only screen and (max-width: 991px) {
  .form_custom .input-field .select-wrapper input {
    height: 46px;
  }
}
.form_custom .input-field .select-wrapper input[type="checkbox"] {
  padding: 0;
  height: 0;
}
.form_custom .input-field .select-wrapper [type="checkbox"]:checked+span:not(.lever):before {
  left: 0;
  top: 0;
}
.form_custom .input-field .select-wrapper [type="checkbox"]+span:not(.lever):before,
.form_custom .input-field .select-wrapper [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
  top: 2px;
}
.form_custom .input-field .select-wrapper .disabled.selected span {
  color: #808080;
}
.form_custom .input-field .select-wrapper .dropdown-content li> a,
.form_custom .input-field .select-wrapper .dropdown-content li>span {
  color: #111;
}
.form_custom .input-field textarea {
  resize: vertical;
  border-bottom: 2px solid #e0dfe0 !important;
  padding-bottom: 12px;
  margin-bottom: 0;
  padding-top: 4px;
  height: 144px;
  box-shadow: none !important;
  color: #111;
  font-size: 22px;
  letter-spacing: -0.2px;
  line-height: 32px;
}
@media only screen and (max-width: 991px) {
  .form_custom .input-field textarea {
    font-size: 18px;
    letter-spacing: -0.16px;
    line-height: 24px;
    padding-top: 8px;
  }
}
.form_custom .input-field textarea::placeholder {
  color: rgba(17,17,17,0.3);
}
.form_custom .input-field input {
  color: #111;
  font-size: 22px;
  letter-spacing: -0.2px;
  line-height: 32px;
  border-bottom: 2px solid #e0dfe0 !important;
  box-shadow: none !important;
  height: auto;
  padding-bottom: 12px;
  margin-bottom: 0;
  padding-top: 4px;
/*&:-internal-autofill-selected
        background-color black!important
        color: -internal-light-dark-color(#455566, #667111)!important*/
}
@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}
@media only screen and (max-width: 991px) {
  .form_custom .input-field input {
    font-size: 18px;
    letter-spacing: -0.16px;
    line-height: 24px;
    padding-top: 8px;
  }
}
.form_custom .input-field input.invalid {
  border-bottom: 2px solid #d51716 !important;
}
.form_custom .input-field input.valid {
  border-bottom: 2px solid #05c18a !important;
}
.form_custom .input-field input:focus {
  border-bottom: 2px solid #111 !important;
}
.form_custom .input-field input:focus.invalid {
  border-bottom: 2px solid #d51716 !important;
}
.form_custom .input-field input::placeholder {
  color: rgba(17,17,17,0.3);
}
.form_custom.form-email-domain-validation {
  position: relative;
}
.form_custom.form-email-domain-validation .form-email-error {
  display: none;
  position: absolute;
  bottom: -12px;
  top: unset;
  line-height: 12px;
  color: #d51716;
  font-size: 14px;
}
.form-error {
  position: absolute;
  top: 78px;
  line-height: 12px !important;
  left: 0;
  color: #d51716 !important;
  font-size: 14px !important;
  letter-spacing: -0.03rem;
}
@media only screen and (max-width: 767px) {
  .form-error {
    top: 74px;
  }
}
@media only screen and (min-width: 992px) {
  .tablet-visible {
    display: none !important;
  }
}
@media only screen and (max-width: 991px) {
  .desctop-visible {
    display: none !important;
  }
  .tablet-visible {
    display: inline-flex !important;
  }
}
.mobile-only__text {
  display: none;
}
@media only screen and (max-width: 600px) {
  .tablet-only__text {
    display: none;
  }
  .mobile-only__text {
    display: inline-block;
  }
}
.collapse_custom {
  margin-top: 48px;
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}
@media only screen and (max-width: 600px) {
  .collapse_custom {
    margin-top: 0;
  }
}
.collapse_custom li {
  padding-bottom: 4px;
}
.collapse_custom li.active .collapsible-header:after {
  display: none;
}
.collapse_custom .collapsible-body {
  padding: 10px 64px 32px 64px;
  background-color: #fff;
  border-bottom: none;
}
@media only screen and (max-width: 600px) {
  .collapse_custom .collapsible-body {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.collapse_custom .collapsible-header {
  border-bottom: none;
  outline: none;
  padding: 24px 64px 24px 64px;
  position: relative;
}
.collapse_custom .collapsible-header:hover:after,
.collapse_custom .collapsible-header:hover:before {
  background-color: #111;
}
.collapse_custom .collapsible-header:after {
  content: '';
  position: absolute;
  display: block;
  width: 2px;
  height: 10px;
  background-color: #e0dfe0;
  left: 31px;
  top: 38px;
  transition: background-color 0.2s ease;
}
@media only screen and (max-width: 991px) {
  .collapse_custom .collapsible-header:after {
    top: 32px;
  }
}
.collapse_custom .collapsible-header:before {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 2px;
  background-color: #e0dfe0;
  left: 27px;
  top: 42px;
  transition: background-color 0.2s ease;
}
@media only screen and (max-width: 991px) {
  .collapse_custom .collapsible-header:before {
    top: 36px;
  }
}
.collapse_custom .collapsible-header h3 {
  margin: 0;
}
@media only screen and (max-width: 600px) {
  .collapse_custom .collapsible-header h3 {
    font-size: 20px;
  }
  .collapse_custom .collapsible-header:after {
    left: 16px;
  }
  .collapse_custom .collapsible-header:before {
    left: 12px;
  }
}
.custom__list li {
  color: #6e6e6e;
  font-size: 22px;
  letter-spacing: -0.2px;
  line-height: 32px;
  padding-bottom: 0;
  position: relative;
  padding-left: 16px;
  max-width: 380px;
}
@media only screen and (max-width: 991px) {
  .custom__list li {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }
  .custom__list li:before {
    top: 12px;
  }
}
.custom__list li:before {
  content: '';
  display: block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 14px;
  background-color: #5a3cc5;
}
.custom__list-check {
  margin-bottom: 24px;
}
.custom__list-check:last-child {
  margin-bottom: 0;
}
.custom__list-check.custom__list-check_purple li:before {
  background: url("/assets/img/check-icon_purple.svg") center no-repeat;
  background-size: cover;
}
.custom__list-check.custom__list-check_green li:before {
  background: url("/assets/img/check-icon.svg") center no-repeat;
  background-size: cover;
}
.custom__list-check.custom__list-check_white li:before {
  background: url("/assets/img/check-icon_white.svg") center no-repeat;
  background-size: cover;
}
.custom__list-check.custom__list-check_black li:before {
  background: url("https://www.altoros.com/images/uploads/check-svg-black-620ba1859ec7f.svg") center no-repeat;
  background-size: cover;
}
.custom__list-check.custom__list-check_red li:before {
  background: url("/assets/img/check-icon_red.svg") center no-repeat;
  background-size: cover;
}
.custom__list-check.custom__list-check_yellow li:before {
  background: url("/assets/img/check-icon_yellow.svg") center no-repeat;
  background-size: cover;
}
.custom__list-check.custom__list-circle_purple li:before {
  width: 6px;
  height: 6px;
  top: 12px;
  border-radius: 0;
  background: none;
  background-color: #5a3cc5;
}
@media only screen and (max-width: 991px) {
  .custom__list-check.custom__list-circle_purple li:before {
    top: 11px;
  }
}
.custom__list-check.custom__list-circle_white li {
  color: #fff;
}
.custom__list-check.custom__list-circle_white li:before {
  width: 6px;
  height: 6px;
  top: 12px;
  border-radius: 0;
  background: none;
  background-color: #fff;
}
@media only screen and (max-width: 991px) {
  .custom__list-check.custom__list-circle_white li:before {
    top: 11px;
  }
}
.custom__list-check li {
  font-size: 20px;
  letter-spacing: -0.2px;
  line-height: 32px;
  color: #6e6e6e;
  position: relative;
  padding-left: 28px;
}
.custom__list-check li:last-child {
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 991px) {
  .custom__list-check li {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }
  .custom__list-check li:before {
    top: 8px;
  }
}
.custom__list-check li:before {
  content: '';
  display: block;
  height: 11px;
  width: 12px;
  position: absolute;
  left: 0;
  top: 10px;
  background: url("/assets/img/check-icon.svg") center no-repeat;
  background-size: cover;
}
.download-img {
  margin-left: 16px;
}
@media only screen and (max-width: 991px) {
  .download-img {
    display: none;
  }
}
.look-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #f7f7f7;
  transition: all 0.2s ease;
}
.look-block .image_svg path {
  transition: all 0.2s ease;
}
.learn-more__link-purple:after {
  background-color: rgba(84,69,191,0.3);
}
.learn-more__link-yellow:after {
  background-color: rgba(100,69,0,0.5);
}
.slick-slide:focus {
  outline: none;
}
.slick-disabled {
  cursor: auto !important;
}
.hide-icons .animated-icon {
  display: none !important;
}
.s-slider__arrows {
  max-width: 256px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.s-slider__arrows .s-slider-arrows__left,
.s-slider__arrows .s-slider-arrows__right {
  flex-basis: 40px;
}
.s-slider__arrows .s-slider-arrows__left .arrow-color,
.s-slider__arrows .s-slider-arrows__right .arrow-color {
  display: block;
}
.s-slider__arrows .s-slider-arrows__left .arrow-grey,
.s-slider__arrows .s-slider-arrows__right .arrow-grey {
  display: none;
}
.s-slider__arrows .s-slider-arrows__left:focus,
.s-slider__arrows .s-slider-arrows__right:focus {
  background-color: transparent !important;
}
.s-slider__arrows .s-slider-arrows__left.slick-disabled .arrow-grey,
.s-slider__arrows .s-slider-arrows__right.slick-disabled .arrow-grey {
  display: block;
}
.s-slider__arrows .s-slider-arrows__left.slick-disabled .arrow-color,
.s-slider__arrows .s-slider-arrows__right.slick-disabled .arrow-color {
  display: none;
}
.s-slider__arrows .s-slider-arrows__left img {
  transform: rotate(180deg);
}
.s-slider__arrows .slick-hidden + .s-slider-count {
  display: none;
}
.s-slider__arrows .s-slider-count {
  display: flex;
  user-select: none;
}
.s-slider__arrows .s-slider-count .slider-count__current,
.s-slider__arrows .s-slider-count .slider-count__amount,
.s-slider__arrows .s-slider-count .slider-count__slash {
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  color: #a0a0a0;
  font-family: 'circular-nova-medium', Sans-Serif;
  width: 30px;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .s-slider__arrows .s-slider-count .slider-count__current,
  .s-slider__arrows .s-slider-count .slider-count__amount,
  .s-slider__arrows .s-slider-count .slider-count__slash {
    font-size: 18px;
    line-height: 25px;
  }
}
.s-slider__arrows .s-slider-count .slider-count__current {
  color: #5a3cc5;
}
.slick-initialized .slick-slide {
  height: auto;
}
.active-link {
  color: #5a3cc5 !important;
}
.white-side-right .container {
  position: relative;
}
.white-side-right .container:after {
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 1000px;
  top: 0;
  background-color: #fff;
  right: -1000px;
}
.white-side-left .container {
  position: relative;
}
.white-side-left .container:before {
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 1000px;
  top: 0;
  background-color: #fff;
  right: -1000px;
}
.s-slider-bg-image__text-slider .slick-list,
.custom-slider__arrows-slider .slick-list {
  overflow: visible;
}
@media only screen and (min-width: 1024px) {
  .s-slider-bg-image__text-slider .slick-slide,
  .custom-slider__arrows-slider .slick-slide {
    opacity: 0.3;
  }
}
.s-slider-bg-image__text-slider .slick-slide.slick-active,
.custom-slider__arrows-slider .slick-slide.slick-active {
  opacity: 1;
}
.s-slider-bg-image__text-slider .slick-slide.slick-current .custom-slider__num,
.custom-slider__arrows-slider .slick-slide.slick-current .custom-slider__num {
  transform: translateX(83px);
}
.s-slider-bg-image__text-slider.all-slides-show .custom-slider__num,
.custom-slider__arrows-slider.all-slides-show .custom-slider__num {
  transform: none !important;
}
.s-slider-bg-image__text-slider .custom-slider__num,
.custom-slider__arrows-slider .custom-slider__num {
  top: -32px;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  height: 32px;
  color: #5a3cc5;
  font-family: 'circular-nova-medium', Sans-Serif;
  width: 30px;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease-in;
}
@media only screen and (max-width: 1220px) {
  .s-slider-bg-image__text-slider .custom-slider__num,
  .custom-slider__arrows-slider .custom-slider__num {
    transform: translateX(0px);
  }
}
@media only screen and (max-width: 991px) {
  .s-slider-bg-image__text-slider .custom-slider__num,
  .custom-slider__arrows-slider .custom-slider__num {
    top: -25px;
    line-height: 25px;
    height: 25px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-slider-bg-image__text-slider,
  .custom-slider__arrows-slider {
    width: 100vw;
    margin-left: -20px;
  }
  .s-slider-bg-image__text-slider .slick-slide,
  .custom-slider__arrows-slider .slick-slide {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .s-slider-bg-image__text-slider,
  .custom-slider__arrows-slider {
    margin-left: -48px;
  }
  .s-slider-bg-image__text-slider .slick-slide,
  .custom-slider__arrows-slider .slick-slide {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media only screen and (max-width: 600px) {
  .s-slider-bg-image__text-slider,
  .custom-slider__arrows-slider {
    margin-left: -32px;
  }
  .s-slider-bg-image__text-slider .slick-slide,
  .custom-slider__arrows-slider .slick-slide {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media only screen and (max-width: 359px) {
  .s-slider-bg-image__text-slider,
  .custom-slider__arrows-slider {
    margin-left: -15px;
  }
  .s-slider-bg-image__text-slider .slick-slide,
  .custom-slider__arrows-slider .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.custom-slider__arrows-slider-related .slick-list {
  overflow: visible;
}
@media only screen and (min-width: 1024px) {
  .custom-slider__arrows-slider-related .slick-slide {
    opacity: 0.3;
  }
}
.custom-slider__arrows-slider-related .slick-slide.slick-active {
  opacity: 1;
}
.custom-slider__arrows-slider-related .slick-slide.slick-current .custom-slider__num {
  transform: translateX(83px);
}
.custom-slider__arrows-slider-related.all-slides-show .custom-slider__num {
  transform: none !important;
}
.custom-slider__arrows-slider-related .custom-slider__num {
  top: -32px;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  height: 32px;
  color: #5a3cc5;
  font-family: 'circular-nova-medium', Sans-Serif;
  width: 30px;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease-in;
}
@media only screen and (max-width: 1220px) {
  .custom-slider__arrows-slider-related .custom-slider__num {
    transform: translateX(0px);
  }
}
@media only screen and (max-width: 991px) {
  .custom-slider__arrows-slider-related .custom-slider__num {
    top: -25px;
    line-height: 25px;
    height: 25px;
  }
}
@media only screen and (max-width: 1200px) {
  .custom-slider__arrows-slider-related {
    width: 100vw;
    margin-left: -20px;
  }
  .custom-slider__arrows-slider-related .slick-slide {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .custom-slider__arrows-slider-related {
    margin-left: -48px;
  }
  .custom-slider__arrows-slider-related .slick-slide {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media only screen and (max-width: 600px) {
  .custom-slider__arrows-slider-related {
    margin-left: -32px;
  }
  .custom-slider__arrows-slider-related .slick-slide {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media only screen and (max-width: 359px) {
  .custom-slider__arrows-slider-related {
    margin-left: -15px;
  }
  .custom-slider__arrows-slider-related .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.custom-slider__arrows-slider_modal .slick-list {
  overflow: visible;
}
@media only screen and (min-width: 1024px) {
  .custom-slider__arrows-slider_modal .slick-slide {
    opacity: 0.3;
  }
}
.custom-slider__arrows-slider_modal .slick-slide.slick-active {
  opacity: 1;
}
.custom-slider__arrows-slider_modal .slick-slide.slick-current .custom-slider__num {
  transform: translateX(83px);
}
.custom-slider__arrows-slider_modal.all-slides-show .custom-slider__num {
  transform: none !important;
}
.custom-slider__arrows-slider_modal .custom-slider__num {
  top: -32px;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  height: 32px;
  color: #5a3cc5;
  font-family: 'circular-nova-medium', Sans-Serif;
  width: 30px;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease-in;
}
@media only screen and (max-width: 1220px) {
  .custom-slider__arrows-slider_modal .custom-slider__num {
    transform: translateX(0px);
  }
}
@media only screen and (max-width: 991px) {
  .custom-slider__arrows-slider_modal .custom-slider__num {
    top: -25px;
    line-height: 25px;
    height: 25px;
  }
}
.infoblock {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.infoblock p {
  cursor: pointer;
  margin: 0;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #ff9b85;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #d51716;
}
.infoblock-content {
  position: absolute;
  bottom: 40px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.3s ease;
  background: #111;
  padding: 17px;
  text-align: left;
  color: #fff;
  font-size: 13px;
  min-width: 300px;
}
.infoblock-content a {
  font-size: 13px;
  color: #fff;
  text-decoration: underline;
}
.infoblock:hover .infoblock-content {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  transition: all 0.3s ease;
}
.infoblock-blue p {
  border: 1px solid #5a3cc5;
  color: #5a3cc5;
}
.timecalc-inputs .input-field {
  display: flex;
  align-items: stretch;
}
.timecalc-inputs .input-field-title {
  position: relative;
  display: inline-block;
  padding: 20px;
  background-color: #fff;
  color: #111;
  margin-right: 10px;
  min-width: 160px;
  max-width: 370px;
}
.timecalc-inputs .input-field input.select-dropdown {
  padding-left: 20px;
  padding-top: 9px;
}
.timecalc-inputs .input-field .select-wrapper {
  display: inline-block;
  background-color: #fff;
  min-width: 150px;
}
.timecalc-inputs .input-field .select-wrapper .dropdown-trigger {
  box-sizing: border-box;
  padding-right: 30px;
  border-bottom: none;
  height: 100%;
}
.timecalc-inputs .input-field .select-wrapper ul {
  width: auto !important;
}
.timecalc-inputs .input-field .select-dropdown {
  border-bottom: none;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 300px;
}
.timecalc-inputs .input-field .select-dropdown:focus {
  border-bottom: none;
}
.timecalc-inputs .input-field .select-dropdown>li {
  white-space: nowrap;
}
.timecalc-inputs .input-field .select-dropdown>li span {
  color: #111;
}
.timecalc-text {
  padding-top: 20px;
}
.timecalc-result {
  position: relative;
  background-color: #5a3cc5;
  text-align: center;
  padding: 20px;
  max-width: 360px;
}
.timecalc-result-title {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
}
.timecalc-result span {
  font-size: 20px;
  color: #fff;
}
.timecalc-result-subtext {
  padding-top: 25px;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}
.timecalc-result a {
  font-size: 14px;
  line-height: 16px;
  color: #05c18a;
}
.timecalc-result-calc .hidden {
  display: none;
}
.timecalc-result-notcalc .hidden {
  display: none;
}
.timecalc-result-notcalc .timecalc-result-title {
  font-size: 18px;
  line-height: 24px;
}
.timecalc-result-notcalc .timecalc-result-subtext {
  font-size: 18px;
  line-height: 24px;
}
.timecalc-result-notcalc .timecalc-result-subtext a {
  font-size: 18px;
  line-height: 24px;
}
.timecalc-main {
  padding-bottom: 100px;
  border-bottom: 1px solid #e5e5e5;
}
@media (min-width: 601px) {
  .timecalc-inputs .input-field-title {
    min-width: 300px;
    padding: 20px 40px;
    margin-right: 20px;
  }
}
@media (min-width: 1201px) {
  .timecalc-inputs .input-field-title {
    width: 100%;
  }
  .timecalc-result {
    margin: 106px 50px 40px;
    padding: 35px 30px;
  }
  .timecalc-result:before {
    content: '';
    position: absolute;
    left: -56px;
    height: 200%;
    top: -50%;
    width: 1px;
    background-color: #e5e5e5;
  }
  .timecalc-text {
    padding-top: 0;
    padding-bottom: 50px;
  }
}
.modal {
  border-radius: 0;
  outline: none;
  border: none;
  box-shadow: none;
}
.modal .modal_training-team {
  height: auto;
}
.modal .modal-thank-you {
  background-color: #fff;
  padding: 40px;
  margin-top: 40px;
}
.modal .modal-thank-you h3 {
  text-align: center;
  margin-bottom: 0;
}
.modal.modal-search {
  width: 100%;
  max-height: 100%;
  height: 100%;
  top: 0 !important;
  bottom: auto !important;
  transition: transform cubic-bezier(0.22, 0.61, 0.36, 1) 0.2s;
  will-change: transform;
  transform: translate(0, -100%) scale(1) !important;
  opacity: 1 !important;
}
.modal.modal-search.open {
  transition: transform cubic-bezier(0.22, 0.61, 0.36, 1) 0.35s 0.25s;
}
.modal.modal-search.open:not([style*="display: none"]):not([style*="opacity: 0;"]) {
  transform: translate(0, 0%) !important;
}
.modal.modal-search .modal-close {
  top: 10%;
  right: 10%;
  width: 16px;
  height: 16px;
}
@media only screen and (max-width: 1200px) {
  .modal.modal-search .modal-close {
    right: 48px;
  }
}
@media only screen and (max-width: 600px) {
  .modal.modal-search .modal-close {
    right: 32px;
  }
}
@media only screen and (max-width: 359px) {
  .modal.modal-search .modal-close {
    right: 15px;
  }
}
.modal.modal-search .modal-close svg g {
  fill: #111;
}
.modal.modal-search .modal-content {
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal.modal-search .modal-content form {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .modal.modal-search .modal-content form {
    flex-direction: column;
    align-items: flex-start;
  }
}
.modal.modal-search .modal-content form .search-field {
  margin-bottom: 0;
  max-width: 672px;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .modal.modal-search .modal-content form .search-field {
    max-width: calc(100% - 238px);
  }
}
@media only screen and (max-width: 600px) {
  .modal.modal-search .modal-content form .search-field {
    max-width: 100%;
  }
}
.modal.modal-search .modal-content form .search-field.input-field {
  margin-bottom: 0;
}
.modal.modal-search .modal-content form .search-field input {
  color: #808080;
}
.modal.modal-search .modal-content form .form-btn {
  padding: 16px 58px;
}
@media only screen and (max-width: 600px) {
  .modal.modal-search .modal-content form .form-btn {
    margin-top: 40px;
  }
}
.modal.modal_photo {
  background-color: transparent;
  max-width: 628px;
  max-height: 80%;
}
@media only screen and (max-width: 992px) {
  .modal.modal_photo {
    max-width: 628px;
  }
}
@media only screen and (max-width: 600px) {
  .modal.modal_photo {
    width: 90%;
  }
}
.modal.modal_photo .modal-content {
  padding: 0;
  margin-top: 40px;
  height: calc(70vh - 40px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.modal.modal_photo .modal-content img {
  max-width: 100%;
  max-height: 100%;
}
.modal.modal__form {
  max-height: inherit;
  width: 900px;
  height: 90%;
  padding: 0;
  background-color: transparent;
  top: 5% !important;
}
@media only screen and (max-width: 991px) {
  .modal.modal__form {
    width: 680px;
  }
}
@media only screen and (max-width: 767px) {
  .modal.modal__form {
    width: 90%;
  }
}
.modal.modal__form .modal-content {
  padding: 60px 50px;
  margin-top: 40px;
  max-height: calc(100% - 40px);
  overflow-x: hidden;
  position: relative;
  background-color: #fff;
}
@media only screen and (max-width: 991px) {
  .modal.modal__form .modal-content {
    padding: 48px 48px;
  }
}
@media only screen and (max-width: 400px) {
  .modal.modal__form .modal-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.modal.modal__form .modal-content h2 {
  margin-bottom: 40px;
  font-size: 34px;
  line-height: 42px;
}
@media only screen and (max-width: 991px) {
  .modal.modal__form .modal-content h2 {
    font-size: 28px;
    line-height: 38px;
  }
}
.modal.modal__form .modal-content .form_custom .input-field {
  margin-bottom: 20px;
}
.modal.modal__form .modal-content .checkbox-wrap {
  margin-bottom: 10px !important;
}
.modal.modal__form .modal-content .checkbox-wrap span {
  font-size: 16px;
}
.modal.modal__form .modal-content .checkbox-wrap span a {
  font-size: inherit;
}
.modal.modal__form-big .modal-content {
  background-color: #f7f7f7;
}
.modal.modal__form-big .modal-content .btn__wrap-modal-close {
  margin: 40px 12px 0;
}
@media (min-width: 1201px) {
  .modal.modal__form-big {
    width: 1200px;
    max-width: 90%;
  }
}
@media (max-width: 600px) {
  .modal.modal__form-big .modal-content {
    padding: 25px 15px;
  }
}
.modal.modal-frame {
  max-width: 520px;
  background-color: transparent;
  padding-top: 40px;
}
.modal.modal__form-preview {
  max-width: 1100px;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .modal.modal__form-preview {
    width: 680px;
  }
}
@media only screen and (max-width: 767px) {
  .modal.modal__form-preview {
    width: 90%;
  }
}
.modal.modal__form-preview .modal-content {
  padding: 0;
}
.modal.modal__form-preview .modal-content .modal-content-inner {
  display: flex;
  height: 100%;
}
.modal.modal__form-preview .modal-content .modal-content-inner .modal-content-preview {
  background-color: #5a3cc5;
  width: 40%;
  padding: 64px 20px 64px 48px;
  flex-shrink: 0;
}
@media only screen and (max-width: 991px) {
  .modal.modal__form-preview .modal-content .modal-content-inner .modal-content-preview {
    display: none;
  }
}
.modal.modal__form-preview .modal-content .modal-content-inner .modal-content-preview h3,
.modal.modal__form-preview .modal-content .modal-content-inner .modal-content-preview p {
  color: #fff;
}
.modal.modal__form-preview .modal-content-form {
  padding: 60px 50px;
}
@media only screen and (max-width: 991px) {
  .modal.modal__form-preview .modal-content-form {
    padding: 48px 48px;
  }
}
@media only screen and (max-width: 400px) {
  .modal.modal__form-preview .modal-content-form {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 992px) {
  .modal.modal__form-preview .modal-content-form h2 {
    display: none;
  }
}
.modal.modal__video {
  background-color: transparent;
  max-height: inherit;
  width: 900px;
  height: 546px;
  overflow: hidden;
}
@media only screen and (max-width: 991px) {
  .modal.modal__video {
    width: 680px;
    height: 422px;
  }
}
@media only screen and (max-width: 767px) {
  .modal.modal__video {
    width: 90%;
    height: 460px;
  }
}
@media only screen and (max-width: 600px) {
  .modal.modal__video {
    height: 340px;
  }
}
@media only screen and (max-width: 450px) {
  .modal.modal__video {
    height: 266px;
  }
}
.modal.modal__video .modal-content {
  padding: 0;
  padding-top: 40px;
  position: relative;
  width: 100%;
  height: 100%;
}
.modal.modal__video .modal-content .modal__video-noiframe,
.modal.modal__video .modal-content .modal__video-iframe_slider,
.modal.modal__video .modal-content .modal__video-iframe {
  width: 100%;
  height: 100%;
}
.modal.modal__video_header .video_header {
  width: 100%;
}
.modal.modal__trainers {
  max-height: inherit;
  width: 80%;
  height: 90%;
  padding: 0;
  background-color: transparent;
  top: 5% !important;
}
@media only screen and (max-width: 1500px) {
  .modal.modal__trainers {
    width: 90%;
  }
}
.modal.modal__trainers.open {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
}
.modal.modal__trainers .modal-content {
  padding: 80px;
  margin-top: 40px;
  height: calc(100% - 40px);
  overflow-x: hidden;
  position: relative;
  background-color: #fff;
}
@media only screen and (max-width: 991px) {
  .modal.modal__trainers .modal-content {
    padding: 48px 48px;
  }
}
@media only screen and (max-width: 400px) {
  .modal.modal__trainers .modal-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.modal.modal__trainers .modal-content .trainers-modal-slider-wrap {
  overflow-x: hidden;
}
.modal.modal__trainers .modal-content .modal__trainer-about {
  display: flex;
  flex-wrap: wrap;
}
.modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-photo {
  width: 33%;
  padding-right: 96px;
}
@media only screen and (max-width: 1400px) {
  .modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-photo {
    padding-right: 64px;
    width: 40%;
  }
}
@media only screen and (max-width: 991px) {
  .modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-photo {
    padding-right: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-photo {
    width: 100%;
    padding-right: 0;
    margin-bottom: 40px;
  }
}
.modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-photo img {
  max-width: 352px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-photo img {
    max-width: 300px;
  }
}
.modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-description {
  width: 67%;
}
@media only screen and (max-width: 1400px) {
  .modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-description {
    width: 60%;
  }
}
@media only screen and (max-width: 767px) {
  .modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-description {
    width: 100%;
  }
}
.modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-description .s-trainers__name {
  margin-top: 0;
  margin-bottom: 16px;
}
@media only screen and (max-width: 991px) {
  .modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-description .s-trainers__name {
    margin-bottom: 8px;
  }
}
.modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-description .s-trainers__position {
  margin-bottom: 32px;
}
.modal.modal__trainers .modal-content .modal__trainer-about .modal__trainer-description p {
  margin-bottom: 16px;
}
.modal.modal__slider-images {
  height: auto;
  max-width: 1480px;
}
.modal.modal__slider-images .modal-content {
  width: 100%;
}
.modal.modal__slider-images .modal__slider-image-photo {
  display: flex;
  justify-content: center;
}
.modal.modal__slider-images .modal__slider-image-photo img {
  max-width: 100%;
}
.modal.training-popup {
  max-height: inherit;
  width: 540px;
  height: 90%;
  padding: 0;
  background-color: transparent;
  top: 5% !important;
}
@media only screen and (max-width: 767px) {
  .modal.training-popup {
    width: 90%;
  }
}
.modal.training-popup .modal-content {
  padding: 0;
  padding-top: 40px;
  height: 100%;
}
.modal .modal-close {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0;
  top: 0;
  z-index: 3;
}
.modal .modal-close:hover svg g {
  fill: #ffab30;
}
.modal .modal-close svg {
  width: 100%;
  height: 100%;
}
.modal .modal-close svg g {
  fill: #f7f7f7;
  transition: all 0.2s ease;
}
.modal.modal_policy {
  width: 95%;
  max-width: 1600px;
  z-index: 10003 !important;
}
.modal.modal--free-trial {
  width: 90%;
  max-width: 1440px;
}
.modal.modal--free-trial h2 {
  margin-bottom: 32px !important;
}
.modal.modal--free-trial .modal__trial-img {
  margin-bottom: 40px;
  max-width: 100%;
}
.modal.modal--free-trial .show-trial-mail-form {
  text-decoration: underline;
  margin-top: 32px;
  margin-bottom: 32px;
  display: none;
}
.modal.modal--free-trial .show-trial-mail-form.active {
  display: inline-block;
}
.modal.modal--free-trial .modal__trial-email-success {
  display: none;
}
.modal.modal--free-trial .form__trial--mail {
  display: none;
  margin-top: 40px;
}
.modal.modal--free-trial .form__trial--mail .form-error {
  position: relative;
  margin-top: 8px;
  bottom: unset !important;
  top: unset !important;
  left: unset !important;
  line-height: 1 !important;
}
.modal.modal--free-trial .form__trial--mail.active {
  display: block;
}
.modal.modal-discount {
  width: 90%;
  max-width: 1440px;
}
.modal.modal-discount h2 {
  margin-bottom: 32px !important;
}
.modal-discount__top-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-discount__top-wrap .form_custom {
  margin-top: 0 !important;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 50%;
  padding-left: 48px;
  padding-right: 48px;
  background-color: #f7f7f7;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .modal-discount__top-wrap .form_custom {
    width: 100%;
    margin-top: 32px !important;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.modal-discount__top-wrap .form_custom:after {
  content: '';
  display: block;
  width: 60px;
  height: 100%;
  background-color: #f7f7f7;
  position: absolute;
  top: 0;
  right: -60px;
}
@media only screen and (max-width: 992px) {
  .modal-discount__top-wrap .form_custom:after {
    display: none;
  }
}
.modal-discount__top-wrap .form_custom .btn__wrap {
  margin-top: 12px;
}
.modal-discount__top-wrap .modal--free-discount-banner {
  max-width: 50%;
}
.modal-discount__top-wrap .modal--free-discount-banner img {
  max-width: 100%;
}
@media only screen and (max-width: 1499px) {
  .modal-discount__top-wrap {
    flex-direction: column-reverse;
  }
  .modal-discount__top-wrap .form_custom {
    max-width: 670px;
    width: 100%;
  }
  .modal-discount__top-wrap .form_custom:after {
    display: none;
  }
  .modal-discount__top-wrap .modal--free-discount-banner {
    max-width: 670px;
    width: 100%;
  }
}
.modal-discount__step-first.hidden {
  display: none;
}
.modal-discount__step-second {
  display: none;
  align-items: center;
  justify-content: space-between;
}
.modal-discount__step-second.active {
  display: flex;
}
.modal-discount__step-second img {
  padding-right: 80px;
}
@media only screen and (max-width: 1299px) {
  .modal-discount__step-second {
    flex-direction: column;
  }
  .modal-discount__step-second img {
    padding-right: 0;
    margin-top: 60px;
    max-width: 460px;
    width: 100%;
  }
}
.modal-discount-thank_text {
  font-size: 20px;
  color: #6e6e6e;
}
.modal-discount-download span {
  text-decoration: underline;
}
.modal-trial__descr-wrap {
  width: 50%;
  padding-right: 48px;
  margin-top: 32px;
}
@media only screen and (max-width: 992px) {
  .modal-trial__descr-wrap {
    width: 100%;
    padding-right: 0;
    margin-top: 0;
  }
}
.modal-trial__descr-wrap p {
  margin-bottom: 16px !important;
}
.modal-trial__top-wrap {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 992px) {
  .modal-trial__top-wrap {
    flex-wrap: wrap;
  }
}
.modal-trial__top-wrap .form_custom {
  margin-top: 0 !important;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 50%;
  padding-left: 48px;
  padding-right: 48px;
  background-color: #f7f7f7;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .modal-trial__top-wrap .form_custom {
    width: 100%;
    margin-top: 32px !important;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.modal-trial__top-wrap .form_custom:after {
  content: '';
  display: block;
  width: 60px;
  height: 100%;
  background-color: #f7f7f7;
  position: absolute;
  top: 0;
  right: -60px;
}
@media only screen and (max-width: 992px) {
  .modal-trial__top-wrap .form_custom:after {
    display: none;
  }
}
.modal-trial__top-wrap .form_custom .btn__wrap {
  margin-top: 40px;
}
.modal-trial__descr-title {
  font-weight: 600;
  color: #111;
}
.s-process-wrap--modal-trial .s-process-container__inner {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.s-process-wrap--modal-trial .s-process-container__inner .s-process__block-text {
  color: #111;
}
.image_animated {
  display: none;
}
.seen .image_animated {
  display: block;
}
.preloader-wrapper {
  height: 64px;
  margin-left: 64px;
  display: none;
  margin-top: 0;
}
.preloader-wrapper svg {
  height: 100%;
}
.range-wrap {
  padding: 60px 40px;
  width: 100%;
  max-width: 650px;
  background-color: #37316f;
}
@media only screen and (max-width: 767px) {
  .range-wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.range-wrap .noUi-target {
  height: 2px;
  border: none;
}
.range-wrap .noUi-connect {
  background: linear-gradient(270deg, #00c088 1.42%, #00c088 100%);
}
.range-wrap .noUi-tooltip {
  padding: 0;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  background-color: transparent;
  border: none;
}
.range-wrap .noUi-marker {
  display: none;
}
.range-wrap .noUi-value {
  top: -2px;
  font-size: 20px;
  color: #a0a0a0;
}
.range-wrap .noUi-value:last-child {
  padding-right: 48px;
}
.range-wrap .noUi-handle {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #00c088;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  cursor: pointer;
  top: -11px;
}
.range-wrap .noUi-handle:before {
  height: 10px;
  width: 2px;
  background: #087c5a;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.range-wrap .noUi-handle:after {
  width: 10px;
  height: 4px;
  border-left: 2px solid #087c5a;
  border-right: 2px solid #087c5a;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}
.daterangepicker {
  padding-top: 1px;
}
.daterangepicker .calendar-table thead td,
.daterangepicker .calendar-table thead th {
  font-family: "circular-nova-medium", Sans-Serif !important;
}
.daterangepicker .calendar-table tbody td,
.daterangepicker .calendar-table tbody th {
  font-family: 'circular-nova-regular', Sans-Serif !important;
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  line-height: 0 !important;
}
.daterangepicker .calendar-table .in-range {
  background-color: rgba(84,69,191,0.3) !important;
  color: #5a3cc5 !important;
}
.daterangepicker .calendar-table .active {
  background-color: #5a3cc5 !important;
  color: #fff !important;
}
.daterangepicker .drp-buttons .btn {
  height: auto;
  background-color: #5a3cc5;
  line-height: 24px;
}
@media only screen and (max-width: 563px) {
  .daterangepicker .drp-buttons {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .daterangepicker .drp-buttons .btn-default {
    margin-left: 0 !important;
  }
  .daterangepicker .drp-buttons .btn-primary {
    margin-left: 6px !important;
  }
}
.linkedin-btn {
  display: flex;
  max-width: 222px;
  border: 1px solid #0077ab;
  margin-bottom: 40px;
  cursor: pointer;
}
.linkedin-btn .linkedin-image {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.linkedin-btn .linkedin-image img {
  width: 20px;
}
.linkedin-btn .linkedin-text {
  color: #0077ab;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #0077ab;
  text-align: center;
  width: calc(100% - 50px);
  font-size: 1rem;
}
[v-cloak] {
  display: none !important;
}
.hbspt-form {
  display: none;
}
.leadinModal-v3.leadinModal.leadinModal-theme-bottom-right-corner.leadinModal-form .leadinModal-content {
  border-color: #5a3cc5 !important;
}
.leadinModal.leadinModal-v3 .leadin-button,
.leadinModal.leadinModal-v3 .leadin-button:hover {
  background: #291b66 !important;
  color: #fff !important;
}
#leadinModal-227150.leadinModal-227150.leadinModal-theme-bottom-right-corner.leadinModal-preview.leadinModal-v3 .leadinModal-content {
  background: #5a3cc5 !important;
}
@media only screen and (max-width: 2560px) {
  .leadinModal-705428,
  .leadinModal-388837 {
    padding-top: 100px !important;
  }
  .leadinModal-705428 .leadinModal-content,
  .leadinModal-388837 .leadinModal-content {
    max-height: 80% !important;
  }
}
.modal-thank-content {
  display: flex;
  justify-content: center;
}
.modal-thank-content .modal-thank__right {
  max-width: 300px;
}
.modal-thank-content .modal-thank__right svg {
  max-width: 100%;
  max-height: 350px;
}
.modal-thank-content .modal-thank__links {
  padding-top: 30px;
}
@media only screen and (max-width: 1399px) {
  .modal-thank-content .modal-thank__right svg {
    max-height: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .modal-thank-content .modal-thank__right {
    display: none;
  }
}
.modal-close-button {
  position: relative !important;
  width: auto !important;
  height: auto !important;
}
.hiddendiv.common {
  display: none;
}
.policy {
  overflow: inherit;
}
.policy .policy-header-candidates {
  padding-bottom: 58px;
  margin-bottom: 98px;
  border-bottom: 2px solid #e0dfe0;
}
.policy .policy-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media only screen and (max-width: 500px) {
  .policy .policy-content .collapsible-body {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.policy .policy-content .policy-main {
  width: 60%;
}
@media only screen and (max-width: 1400px) {
  .policy .policy-content .policy-main {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .policy .policy-content .policy-main {
    display: none;
  }
}
.policy .policy-content .policy-aside-mobile {
  display: none;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .policy .policy-content .policy-aside-mobile {
    display: block;
  }
}
.policy .policy-content .policy-aside-desktop {
  background-color: #fff;
  padding: 32px 40px 16px;
  width: 35%;
}
@media only screen and (max-width: 1400px) {
  .policy .policy-content .policy-aside-desktop {
    width: 45%;
  }
}
@media only screen and (max-width: 991px) {
  .policy .policy-content .policy-aside-desktop {
    display: none;
  }
}
.policy .policy-content .policy-aside-desktop .policy-aside-first {
  margin-bottom: 0;
}
.policy .policy-content .policy-aside-desktop .policy-aside-item-title {
  color: #5a3cc5;
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 20px;
  letter-spacing: -0.27px;
  line-height: 32px;
}
@media only screen and (max-width: 991px) {
  .policy .policy-content .policy-aside-desktop .policy-aside-item-title {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }
}
.policy .policy-content .policy-aside-desktop .policy-aside-item-title .policy-aside-item-num {
  color: #5a3cc5;
  font-size: 20px;
  letter-spacing: -0.27px;
  line-height: 32px;
}
@media only screen and (max-width: 991px) {
  .policy .policy-content .policy-aside-desktop .policy-aside-item-title .policy-aside-item-num {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }
}
.policy .policy-content .policy-aside-item-num {
  color: #111;
  margin-right: 8px;
  font-family: "circular-nova-medium";
  font-size: 24px;
  letter-spacing: -0.27px;
  line-height: 36px;
}
@media only screen and (max-width: 991px) {
  .policy .policy-content .policy-aside-item-num {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.1px;
  }
}
.policy .policy-content .policy-aside-item-title {
  color: #111;
  cursor: auto;
  margin-bottom: 32px;
  display: inline-block;
  font-family: "circular-nova-medium";
  font-size: 24px;
  letter-spacing: -0.27px;
  line-height: 36px;
}
.policy .policy-content .policy-aside-item-title:before {
  display: none;
}
@media only screen and (max-width: 991px) {
  .policy .policy-content .policy-aside-item-title {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.1px;
  }
}
.policy .policy-content .policy-aside-item {
  padding-left: 0;
}
.policy .policy-content .policy-aside-collapse:before {
  display: none;
}
.policy .policy-content h2 {
  margin-bottom: 32px;
  margin-top: 72px;
}
@media only screen and (max-width: 991px) {
  .policy .policy-content h2 {
    margin-bottom: 24px;
    margin-top: 56px;
  }
}
.policy .policy-content ul,
.policy .policy-content ol {
  margin-bottom: 32px;
}
@media only screen and (max-width: 991px) {
  .policy .policy-content ul,
  .policy .policy-content ol {
    margin-bottom: 24px;
  }
}
.policy .policy-content ul li,
.policy .policy-content ol li {
  list-style: none;
}
.policy .policy-content ul li {
  color: #6e6e6e;
  font-size: 20px;
  letter-spacing: -0.2px;
  line-height: 32px;
  padding-bottom: 0;
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 991px) {
  .policy .policy-content ul li {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }
}
.policy .policy-content ul li:before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 0;
  top: 12px;
  border-radius: 0;
  background: none;
  background-color: #5a3cc5;
}
@media only screen and (max-width: 991px) {
  .policy .policy-content ul li:before {
    top: 11px;
  }
}
.policy_modal-link {
  display: inline-block;
}
iframe[src*=clickcertain] {
  display: none;
}
.s-search-content .indicator {
  display: none !important;
}
.s-search-content .tab__search-link.active {
  position: relative;
}
.s-search-content .tab__search-link.active:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #5a3cc5;
  bottom: 0;
  left: 0;
}
.select-wrap {
  position: relative;
}
@media (pointer: coarse) {
  .select-wrap select {
    position: relative;
    border: 0;
    background-color: transparent;
    box-shadow: 0 0;
    border-radius: 0;
    border-bottom: 1px solid #e0dfe0;
    outline: 0;
    padding-left: 0;
    padding-right: 40px;
  }
  .select-wrap select.valid {
    border-bottom-color: #05c18a;
  }
  .select-wrap:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #111;
    border-bottom: 2px solid #111;
    transform: rotate(-45deg) translateY(-50%);
    position: absolute;
    right: 10px;
    top: 50%;
  }
}
.select-wrap .form-error {
  top: 58px;
}
@-moz-keyframes imageMatrixAnimation {
  0% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
  12.5% {
    transform: matrix(1, 0, 0, 1, -675, 0);
  }
  25% {
    transform: matrix(1, 0, 0, 1, -900, 0);
  }
  37.5% {
    transform: matrix(1, 0, 0, 1, -675, 0);
  }
  50% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
  62.5% {
    transform: matrix(1, 0, 0, 1, -225, 0);
  }
  75% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  87.5% {
    transform: matrix(1, 0, 0, 1, -225, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
}
@-webkit-keyframes imageMatrixAnimation {
  0% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
  12.5% {
    transform: matrix(1, 0, 0, 1, -675, 0);
  }
  25% {
    transform: matrix(1, 0, 0, 1, -900, 0);
  }
  37.5% {
    transform: matrix(1, 0, 0, 1, -675, 0);
  }
  50% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
  62.5% {
    transform: matrix(1, 0, 0, 1, -225, 0);
  }
  75% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  87.5% {
    transform: matrix(1, 0, 0, 1, -225, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
}
@-o-keyframes imageMatrixAnimation {
  0% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
  12.5% {
    transform: matrix(1, 0, 0, 1, -675, 0);
  }
  25% {
    transform: matrix(1, 0, 0, 1, -900, 0);
  }
  37.5% {
    transform: matrix(1, 0, 0, 1, -675, 0);
  }
  50% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
  62.5% {
    transform: matrix(1, 0, 0, 1, -225, 0);
  }
  75% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  87.5% {
    transform: matrix(1, 0, 0, 1, -225, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
}
@keyframes imageMatrixAnimation {
  0% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
  12.5% {
    transform: matrix(1, 0, 0, 1, -675, 0);
  }
  25% {
    transform: matrix(1, 0, 0, 1, -900, 0);
  }
  37.5% {
    transform: matrix(1, 0, 0, 1, -675, 0);
  }
  50% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
  62.5% {
    transform: matrix(1, 0, 0, 1, -225, 0);
  }
  75% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  87.5% {
    transform: matrix(1, 0, 0, 1, -225, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, -450, 0);
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
}
@media only screen and (max-width: 400px) {
  .iphone-size {
    min-height: 428px !important;
  }
}
a.gdpr-cookie-notice-nav-item:visited,
a.gdpr-cookie-notice-nav-item,
a.gdpr-cookie-notice-modal-footer-item-save:visited,
a.gdpr-cookie-notice-modal-footer-item-save {
  color: #fff !important;
}
.gdpr-cookie-notice-nav {
  box-shadow: none !important;
}
a.gdpr-cookie-notice-modal-footer-item-save span {
  color: #fff !important;
  font-weight: 400 !important;
}
a.gdpr-cookie-notice-modal-footer-item-statement:visited,
a.gdpr-cookie-notice-modal-footer-item-statement,
.gdpr-cookie-notice-modal-footer-item-btn,
.gdpr-cookie-notice-modal-cookie-state {
  color: #5a3cc5 !important;
}
.gdpr-cookie-notice-modal-cookie-input:checked+.gdpr-cookie-notice-modal-cookie-input-switch {
  background: #5a3cc5 !important;
  box-shadow: inset 0 0 0 1px #5a3cc5 !important;
}
.gdpr-cookie-notice-modal-cookie-state {
  color: #5a3cc5 !important;
}
.gdpr-cookie-notice-modal-footer-item-btn {
  background: #5a3cc5 !important;
}
.gdpr-cookie-notice-nav-item-btn {
  background: none !important;
  border: 1px solid #fff !important;
}
.gdpr-cookie-notice-modal-title {
  font-family: 'circular-nova-regular' !important;
}
.gdpr-cookie-notice {
  background: #fff !important;
}
.gdpr-cookie-notice {
  padding: 5px 50px !important;
  z-index: 10000 !important;
}
@media only screen and (max-width: 992px) {
  .gdpr-cookie-notice {
    padding: 5px 16px !important;
  }
}
.gdpr-cookie-notice-nav-item {
  font-weight: 400 !important;
  background-color: #5a3cc5 !important;
  border-radius: 0 !important;
  border: none !important;
}
.gdpr-cookie-notice-nav-item-accept {
  margin-right: 20px;
}
.gdpr-cookie-notice-nav-item.gdpr-cookie-notice-nav-item-settings:visited,
.gdpr-cookie-notice-nav-item.gdpr-cookie-notice-nav-item-settings {
  background-color: #f9f8fd !important;
  color: #5a3cc5 !important;
}
.gdpr-cookie-notice-description {
  color: #111 !important;
  line-height: 17px;
}
@media (max-width: 375px) {
  .gdpr-cookie-notice-modal-content {
    width: 350px !important;
  }
  .gdpr-cookie-notice {
    padding: 20px !important;
  }
}
.gdpr-cookie-notice-modal {
  z-index: 10001 !important;
}
.gdpr-cookie-notice-modal-cookie-input-switch:after {
  background: url("data:image/svg+xml,%3Csvg%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20width%3D%2214%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m5%2010.42-5-5%201.41-1.41%203.59%203.58%207.59-7.59%201.41%201.42z%22%20fill%3D%22rgb(255%2C255%2C255)%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E") no-repeat center 7px !important;
}
.gdpr-cookie-notice-modal-footer-item:after {
  display: none !important;
  background: url("data:image/svg+xml,%3Csvg%20height%3D%2212%22%20viewBox%3D%220%200%208%2012%22%20width%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m1.41%200-1.41%201.41%204.58%204.59-4.58%204.59%201.41%201.41%206-6z%22%20fill%3D%22%23149a4f%22%2F%3E%3C%2Fsvg%3E") no-repeat left center !important;
}
.gdpr-cookie-notice-modal-footer-item-btn.saved:after {
  background: url("data:image/svg+xml,%3Csvg%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20width%3D%2214%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m5%2010.42-5-5%201.41-1.41%203.59%203.58%207.59-7.59%201.41%201.42z%22%20fill%3D%22rgb(255%2C255%2C255)%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E") no-repeat center !important;
}
.gdpr-cookie-notice-modal-cookie-info {
  font-size: 14px !important;
  color: rgba(0,0,0,0.7) !important;
}
.gdpr-cookie-notice-modal-close:focus {
  outline: none !important;
}
.gdpr-cookie-notice-modal-close:focus {
  background-color: unset;
}
.gdpr-cookie-notice-modal-close:hover:before,
.gdpr-cookie-notice-modal-close:hover:after {
  background-color: #ffab30;
}
.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 12px;
  background-color: transparent;
  padding: 40px 64px;
  z-index: 900;
  box-shadow: none;
  transition: all 0.2s ease;
  height: 112px;
}
@media only screen and (max-width: 1200px) {
  .menu {
    height: 80px;
  }
}
.menu.white-bg {
  background-color: #fff;
}
.menu.menu-white .nav-wrapper .open-menu__btn .open-menu__icon-block .line {
  background-color: #111;
}
.menu.menu-white .nav-wrapper .menu__content .menu-links__no-arrows li a {
  color: #111;
}
.menu.menu-white .nav-wrapper .menu__content .menu-links__no-arrows li a:hover {
  color: #5a3cc5;
}
.menu.menu-white .menu-search-white {
  display: none;
}
.menu.menu-white .menu-search-dark {
  display: block;
}
.menu.menu-dark .nav-wrapper .menu__content .menu-links__no-arrows li:nth-last-child(2) a {
  color: #5a3cc5 !important;
}
.menu.menu-dark .nav-wrapper .menu__content .menu-links__no-arrows li a {
  color: #111;
}
.menu.menu-dark .nav-wrapper .menu__content .menu-links__no-arrows .menu-search-white {
  display: none;
}
.menu.menu-dark .nav-wrapper .menu__content .menu-links__no-arrows .menu-search-dark {
  display: block;
}
.menu.menu-dark .nav-wrapper .open-menu__btn .open-menu__icon-block .line {
  background-color: #111;
}
@media only screen and (max-width: 600px) {
  .menu.dark-mobile-menu .nav-wrapper .open-menu__btn .open-menu__icon-block .line {
    background-color: #111;
  }
}
.menu .container {
  max-width: 1344px;
  padding-left: 0;
  padding-right: 0;
}
.menu .menu-search-white {
  display: none;
}
.menu.not-top {
  padding: 24px 64px;
  background-color: #fff;
  height: 80px;
}
.menu.not-top .menu-search-dark {
  display: block;
}
.menu.not-top .menu-search-white {
  display: none;
}
.menu.not-top .nav-wrapper .menu__content .menu-links__list li a.menu-link {
  color: #111;
}
.menu.not-top .nav-wrapper .menu__content .menu-links__list li a.menu-link:after {
  border-left-color: #111;
  border-bottom-color: #111;
}
.menu.not-top .nav-wrapper .menu__content .menu-links__list li a.menu-link:hover {
  color: #5a3cc5;
}
.menu.not-top .nav-wrapper .menu__content .menu-links__list li a.menu-link:hover:after {
  border-left-color: #5a3cc5;
  border-bottom-color: #5a3cc5;
}
.menu.not-top .nav-wrapper .menu__content .open-menu__btn span {
  color: #111;
}
.menu.not-top .nav-wrapper .menu__content .open-menu__btn .open-menu__icon-block .line {
  background-color: #111;
}
.menu .nav-wrapper .menu__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu .nav-wrapper .menu__content .menu__left-part {
  display: flex;
  align-items: center;
}
.menu .nav-wrapper .menu__content .menu__left-part-second {
  display: flex;
  position: absolute;
  right: 0;
}
@media only screen and (min-width: 1201px) and (max-width: 1500px) {
  .menu .nav-wrapper .menu__content .menu__left-part-second {
    right: 20px;
  }
}
.menu .nav-wrapper .menu__content .brand-logo {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  line-height: 0;
  margin-top: 1px;
}
.menu .nav-wrapper .menu__content .menu-link {
  padding-right: 19px;
  position: relative;
  margin-right: 39px;
  transition: all 0.2s ease;
}
.menu .nav-wrapper .menu__content .menu-link.menu-link-contact {
  padding: 12px 32px;
  background: linear-gradient(91.07deg, #eaf0ff -2.56%, #f0e9fd 104.93%);
  color: #111;
  height: 100%;
  display: flex;
  align-items: center;
}
.menu .nav-wrapper .menu__content .menu-link.menu-link-contact.showed {
  opacity: 1;
}
.menu .nav-wrapper .menu__content .menu-link.menu-link-contact.hided {
  opacity: 0;
}
.menu .nav-wrapper .menu__content .menu-link.menu-link-contact:hover {
  background: linear-gradient(273deg, #e7e0fa 0%, #e1e2fb 41.77%, #dae4fd 85.25%);
  color: #5a3cc5;
}
.menu .nav-wrapper .menu__content .menu-link.active {
  color: #5a3cc5 !important;
}
.menu .nav-wrapper .menu__content .menu-link.active:after {
  transform: rotate(135deg) translateY(90%);
  top: 20px;
  border-left-color: #5a3cc5 !important;
  border-bottom-color: #5a3cc5 !important;
}
.menu .nav-wrapper .menu__content .menu-link.no-arrow {
  padding-right: 0;
}
.menu .nav-wrapper .menu__content .menu-link.no-arrow:after {
  display: none;
}
.menu .nav-wrapper .menu__content .menu-link:hover {
  color: #5a3cc5;
}
.menu .nav-wrapper .menu__content .menu-link:hover:after {
  border-left-color: #5a3cc5;
  border-bottom-color: #5a3cc5;
}
.menu .nav-wrapper .menu__content .menu-link:after {
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  border-left: 2px solid #111;
  border-bottom: 2px solid #111;
  transform: rotate(-45deg) translateY(-100%);
  position: absolute;
  right: -8px;
  top: 50%;
}
.menu .nav-wrapper .menu__content .menu-link_noarrow {
  margin-right: 10px !important;
}
.menu .nav-wrapper .menu__content .menu-link_noarrow:after {
  display: none;
}
.menu .nav-wrapper .menu__content .menu-links__no-arrows li a {
  padding-right: 0;
  margin-right: 12px;
  color: #fff;
}
.menu .nav-wrapper .menu__content .menu-links__no-arrows li a:after {
  display: none;
}
.menu .nav-wrapper .menu__content .menu-links__no-arrows li:nth-last-child(2) a {
  margin-right: 35px;
}
@media only screen and (max-width: 1460px) {
  .menu .nav-wrapper .menu__content .menu-links__no-arrows li:nth-last-child(2) a {
    margin-right: 5px;
  }
}
.menu .nav-wrapper .menu__content .menu-links__no-arrows li:last-child a {
  margin-right: 0;
}
@media only screen and (max-width: 1220px) {
  .menu .nav-wrapper .menu__content .menu-links__no-arrows li:last-child a {
    margin-right: 60px;
  }
}
@media only screen and (max-width: 1200px) {
  .menu .nav-wrapper .menu__content .menu-links__no-arrows li:last-child a {
    margin-right: 40px;
  }
}
.menu .nav-wrapper .menu-links__list {
  display: flex;
  align-items: center;
}
.menu .nav-wrapper .menu-links__list li {
  height: 100%;
}
.menu .nav-wrapper .menu-links__list li:hover .dropdown {
  opacity: 1;
  visibility: visible;
}
.menu .nav-wrapper .menu-links__list li:hover .menu-link::after {
  top: 20px;
  transform: rotate(135deg) translateY(90%);
}
.menu .nav-wrapper .menu-links__list li a {
  font-family: 'circular-nova-medium';
  padding: 0;
  color: #111;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  background-color: transparent !important;
}
.menu .nav-wrapper .menu-links__list li .dropdown {
  max-width: 586px;
  position: absolute;
  top: 45px;
  padding: 16px 0 16px 16px;
  background-color: #fff;
  box-shadow: 0 2px 16px rgba(0,0,0,0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.menu .nav-wrapper .menu-links__list li .dropdown__top-wrap {
  display: flex;
}
.menu .nav-wrapper .menu-links__list li .dropdown__tabs,
.menu .nav-wrapper .menu-links__list li .dropdown__content {
  display: flex;
  flex-direction: column;
}
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-item {
  height: auto;
}
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link {
  display: block;
  padding: 8px 53px 8px 16px;
  font-family: "circular-nova-medium";
  font-size: 16px;
  line-height: 24px;
  color: #111;
  white-space: nowrap;
  cursor: pointer;
}
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link:hover {
  background-color: #f8f8ff !important;
  color: #5a3cc5;
}
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link.activeTab {
  background-color: #f8f8ff !important;
}
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link.click:hover,
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link.submenu:hover {
  color: #5a3cc5;
}
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link.click:hover span::after,
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link.submenu:hover span::after {
  filter: invert(27%) sepia(28%) saturate(6551%) hue-rotate(244deg) brightness(79%) contrast(93%);
}
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link.click span,
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link.submenu span {
  position: relative;
}
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link.click span::after,
.menu .nav-wrapper .menu-links__list li .dropdown__tabs-link.submenu span::after {
  content: "";
  position: absolute;
  right: -21px;
  top: 50%;
  transform: translateY(-50%);
  width: 13px;
  height: 13px;
  background-image: url("https://www.altoros.com/images/uploads/arrow-top-62dfe193e11cd.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.menu .nav-wrapper .menu-links__list li .dropdown__content {
  display: none;
  padding: 8px 32px 8px 20px;
  border-left: 1px solid #e0dfe0;
}
.menu .nav-wrapper .menu-links__list li .dropdown__content.active {
  display: flex;
}
.menu .nav-wrapper .menu-links__list li .dropdown__content-item {
  height: auto;
}
.menu .nav-wrapper .menu-links__list li .dropdown__content-item:not(:last-child) {
  margin-bottom: 16px;
}
.menu .nav-wrapper .menu-links__list li .dropdown__content-link,
.menu .nav-wrapper .menu-links__list li .dropdown__content-sublink {
  font-family: 'circular-nova-regular';
  font-size: 16px;
  line-height: 24px;
  color: #6e6e6e;
}
.menu .nav-wrapper .menu-links__list li .dropdown__content-link:hover,
.menu .nav-wrapper .menu-links__list li .dropdown__content-sublink:hover {
  color: #5a3cc5;
}
.menu .nav-wrapper .menu-links__list li .dropdown__view-all {
  font-family: 'circular-nova-regular';
  margin-top: 12px;
  padding-left: 16px;
  font-size: 16px;
  line-height: 24px;
}
.menu .nav-wrapper .menu-links__list li .dropdown__view-all .learn-more__link-purple-arrow {
  color: #5a3cc5;
}
.menu .nav-wrapper .menu-links__list li .dropdown__view-all .learn-more__link-purple-arrow:before {
  transition: right 0.2s ease;
  background: url("https://www.altoros.com/images/uploads/arrow-purple-menu-5fcf5c0518976.svg") center no-repeat;
}
.menu .nav-wrapper .menu-links__list li .dropdown__view-all:hover .learn-more__link-purple-arrow:before {
  right: -25px;
}
.menu .nav-wrapper .open-menu__btn {
  width: 40px;
  height: 18px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  right: 0;
  display: none;
  transform: translate(0, -50%);
  margin-right: 0;
}
.menu .nav-wrapper .open-menu__btn .open-menu__icon-block {
  height: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.menu .nav-wrapper .open-menu__btn .open-menu__icon-block .line {
  width: 19px;
  height: 2px;
  background-color: #fff;
  margin-bottom: 6px;
}
@media only screen and (max-width: 600px) {
  .menu .nav-wrapper .open-menu__btn .open-menu__icon-block .line {
    background-color: #111;
  }
}
.menu .nav-wrapper .open-menu__btn .open-menu__icon-block .line:last-child {
  margin-bottom: 0;
}
.menu .nav-wrapper .open-menu__btn .open-menu__icon-block .line:nth-child(2) {
  width: 24px;
}
.menu .menu-lang {
  position: relative;
  color: #111;
  font-size: 20px;
  margin-right: 0;
}
@media (min-width: 1221px) and (max-width: 1460px) {
  .menu .menu-lang {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1220px) {
  .menu .menu-lang {
    display: none;
  }
}
.menu .menu-lang__current {
  cursor: pointer;
  position: relative;
  line-height: 32px;
}
.menu .menu-lang__current:before {
  content: '';
  position: absolute;
  border: solid #111;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: translateY(-50%) rotate(45deg) scale(1, 1);
  transition: transform ease 0.2s;
  right: -15px;
  top: 44%;
}
.menu .menu-lang__modal {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all ease 0.2s;
  display: flex;
  flex-direction: column;
  line-height: 1;
  right: 0;
  padding: 20px 25px;
  box-shadow: 1px 3px 5px 0 #808080;
  background-color: #fff;
}
@media only screen and (max-width: 1220px) {
  .menu .menu-lang__modal {
    left: auto;
    right: -20px;
  }
}
.menu .menu-lang__modal a {
  color: #111;
}
.menu .menu-lang__lang {
  display: block;
  padding: 5px 10px;
}
.menu .menu-lang.active .menu-lang__modal {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
}
.menu .menu-lang.active .menu-lang__current:before {
  transform: translateY(-14%) rotate(45deg) scale(-1, -1);
}
@media only screen and (max-width: 600px) {
  .menu .menu-lang {
    margin-left: -20px;
  }
  .menu .menu-lang__modal {
    padding: 10px 15px;
  }
}
.menu.menu-white {
  background-color: #fff;
}
.menu .hide-on-med-and-down {
  transition: opacity 0.5s ease;
}
.menu .hide-on-med-and-down.hided .menu-link-contact {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.menu.gradient-btn .nav-wrapper .menu__content .menu-link.menu-link-contact {
  position: relative;
  overflow: hidden;
  color: #fff !important;
  border-radius: 16px;
  background: none;
}
.menu.gradient-btn .nav-wrapper .menu__content .menu-link.menu-link-contact::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 466px;
  background: linear-gradient(96.51deg, #4428bc -0.28%, #e00a6b 100%), linear-gradient(96.51deg, #eaf0ff -0.28%, #f0e9fd 100%);
  transform: translateX(0);
  background-color: transparent;
  transition: transform 0.2s ease-in;
}
.menu.gradient-btn .nav-wrapper .menu__content .menu-link.menu-link-contact:hover::before {
  transform: translateX(-170px);
}
.menu.gradient-btn.not-top .nav-wrapper .menu__content .menu-link.menu-link-contact {
  color: #fff;
}
@media only screen and (max-width: 1820px) {
  .menu .nav-wrapper .menu-links__list {
    margin-left: 120px;
  }
  .menu .nav-wrapper .menu__content .brand-logo {
    position: relative;
    top: 0;
    transform: none;
  }
  .menu .nav-wrapper .open-menu__btn {
    position: relative;
    top: 0;
    transform: none;
  }
}
@media only screen and (max-width: 1820px) {
  .menu .container {
    max-width: 100%;
  }
  .menu .nav-wrapper .menu-links__list {
    margin-left: 35px;
    align-items: stretch;
  }
}
@media (min-width: 1500px) and (max-width: 1699px) {
  .menu {
    padding-left: 24px;
    padding-right: 36px;
  }
  .menu.not-top {
    padding: 24px 36px 24px 24px;
  }
}
@media only screen and (max-width: 1500px) {
  .menu {
    padding: 32px 6px;
  }
  .menu.not-top {
    padding: 24px 6px;
  }
  .menu .nav-wrapper .menu__content .menu-link {
    margin-right: 24px;
  }
  .menu .nav-wrapper .menu__content .menu-link:after {
    right: -3px;
  }
  .menu .nav-wrapper .menu__content .brand-logo {
    margin-top: 0;
  }
  .menu .nav-wrapper .menu__content .menu-links__no-arrows li:nth-last-child(2) a {
    margin-right: 25px;
  }
  .menu .nav-wrapper .menu-links__list {
    margin-left: 25px;
  }
}
@media only screen and (max-width: 1399px) {
  .menu .nav-wrapper .menu__content .menu-link {
    margin-right: 24px;
  }
  .menu .nav-wrapper .menu-links__list {
    align-items: center;
  }
  .menu .nav-wrapper .menu-links__list li {
    height: auto;
  }
}
@media only screen and (max-width: 1299px) {
  .menu .nav-wrapper .menu__content .menu-link {
    margin-right: 16px;
  }
}
@media only screen and (max-width: 1220px) {
  .menu {
    padding: 24px 20px;
  }
  .menu.not-top {
    padding: 24px 20px;
  }
  .menu .nav-wrapper .open-menu__btn {
    display: flex;
  }
}
@media only screen and (max-width: 1199px) {
  .menu .nav-wrapper .menu__content .menu-link {
    padding-right: 15px;
    margin-right: 20px;
  }
  .menu .nav-wrapper .menu-links__list {
    margin-left: 30px;
  }
  .menu .nav-wrapper .menu-links__list li a {
    font-size: 18px;
  }
}
@media only screen and (max-width: 979px) {
  .menu {
    padding: 26px 48px;
  }
  .menu.not-top {
    padding: 26px 48px;
  }
}
@media only screen and (max-width: 767px) {
  .menu .menu-search-dark {
    display: block;
  }
  .menu .menu-search-white {
    display: none;
  }
  .menu .nav-wrapper .menu__content .open-menu__btn .open-menu__icon-block .line {
    background-color: #111;
  }
}
@media only screen and (max-width: 600px) {
  .menu,
  .menu.not-top {
    padding: 24px;
  }
  .menu .nav-wrapper .open-menu__btn,
  .menu.not-top .nav-wrapper .open-menu__btn {
    width: 24px;
  }
}
@media only screen and (max-width: 360px) {
  .menu,
  .menu.not-top {
    padding: 32px 15px;
  }
}
@media only screen and (max-width: 1220px) {
  .hide-on-med-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 1220px) {
  .sidenav-overlay {
    display: none !important;
  }
}
.mobile-menu.sidenav {
  padding: 32px;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 1221px) {
  .mobile-menu.sidenav {
    display: none;
  }
}
.mobile-menu.sidenav .mobile-sublinks-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mobile-menu.sidenav .mobile-sublinks-wrap-line {
  padding-top: 42px;
  margin-top: 24px;
  border-top: 1px solid #e0dfe0;
}
.mobile-menu.sidenav .mobile-sublinks-col {
  width: 48%;
}
@media only screen and (max-width: 600px) {
  .mobile-menu.sidenav .mobile-sublinks-col {
    width: 100%;
  }
}
.mobile-menu.sidenav .link_header {
  display: inline-flex;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 32px;
}
.mobile-menu.sidenav .link_header .arrow-img {
  margin-left: 8px;
}
.mobile-menu.sidenav .unclick {
  color: #111 !important;
}
.mobile-menu.sidenav .unclick:after {
  display: none !important;
}
.mobile-menu.sidenav .dropdown__block-gated-mobile .gated-block {
  padding-left: 12px;
}
.mobile-menu.sidenav .dropdown__block-gated-mobile h4 {
  margin-top: 0;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 15px;
}
.mobile-menu.sidenav h4 {
  margin-bottom: 8px;
  margin-top: 16px;
  font-size: 22px;
  line-height: 32px;
}
.mobile-menu.sidenav .sidenav__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}
.mobile-menu.sidenav .sidenav__header .brand-logo {
  display: flex;
}
.mobile-menu.sidenav .sidenav__header .sidenav__header-right {
  display: flex;
  align-items: center;
}
.mobile-menu.sidenav .sidenav__header .sidenav__header-right .cancel__btn {
  width: 16px;
  height: 16px;
  position: relative;
  line-height: 16px;
  margin-left: 40px;
}
.mobile-menu.sidenav .sidenav__header .sidenav__header-right .cancel__btn svg {
  width: 16px;
  height: 16px;
}
.mobile-menu.sidenav .sidenav__header .sidenav__header-right .cancel__btn svg g {
  fill: #111;
}
.mobile-menu.sidenav .collapsible-body {
  padding-bottom: 8px;
}
.mobile-menu.sidenav .collapsible-body .mobile-sublinks-block {
  margin-bottom: 32px;
}
.mobile-menu.sidenav .collapsible-body .mobile-sublinks-block .mobile-submenu-header {
  margin-bottom: 16px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 12px;
}
.mobile-menu.sidenav .collapsible-body .mobile-sublinks-block .link {
  margin-bottom: 12px;
  line-height: 0;
  padding-left: 12px;
}
.mobile-menu.sidenav .collapsible-body .mobile-sublinks-block .link a {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  color: #6e6e6e;
}
.mobile-menu.sidenav .collapsible-body .mobile-sublinks-block .link a.learn-more__link-purple {
  color: #5a3cc5;
}
.mobile-menu.sidenav .collapsible-body .dropdown__col_subheader {
  padding-left: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.mobile-menu.sidenav .collapsible-body .dropdown__bottom-link {
  padding-left: 12px;
}
.mobile-menu.sidenav .link-wrap {
  background-color: transparent;
}
.mobile-menu.sidenav .link-wrap.active .menu-link_mobile {
  color: #5a3cc5;
  margin-bottom: 30px;
}
.mobile-menu.sidenav .link-wrap.active .menu-link_mobile.dropdown-link:after {
  transform: rotate(135deg);
  border-left-color: #5a3cc5;
  border-bottom-color: #5a3cc5;
  top: 14px;
}
.mobile-menu.sidenav .link-wrap .menu-link_mobile {
  display: block;
  font-size: 18px;
  font-family: "circular-nova-medium";
  letter-spacing: -0.2px;
  line-height: 32px;
  color: #111;
  transition: all 0.2s ease;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 16px;
  background-color: transparent;
}
.mobile-menu.sidenav .link-wrap .menu-link_mobile-contact {
  display: inline;
  padding: 17px 25px;
  background: linear-gradient(91.07deg, #eaf0ff -2.56%, #f0e9fd 104.93%);
}
.mobile-menu.sidenav .link-wrap .menu-link_mobile.dropdown-link:after {
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  border-left: 2px solid #111;
  border-bottom: 2px solid #111;
  transform: rotate(-45deg);
  position: absolute;
  right: 2px;
  top: 10px;
}
.mobile-menu.sidenav .sidenav-button-tablet {
  display: none;
}
.mobile-menu.sidenav .sidenav-button-tablet .menu-link_mobile {
  display: block;
  font-size: 18px;
  font-family: "circular-nova-medium";
  letter-spacing: -0.2px;
  line-height: 32px;
  color: #111;
  transition: all 0.2s ease;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 16px;
  background-color: transparent;
}
.mobile-menu.sidenav .sidenav-button-tablet .menu-link_mobile-contact {
  display: inline;
  padding: 12px 25px;
  background: linear-gradient(91.07deg, #eaf0ff -2.56%, #f0e9fd 104.93%);
}
@media (min-width: 600px) and (max-width: 1220px) {
  .mobile-menu.sidenav .sidenav-button-tablet {
    display: block;
  }
}
.mobile-menu.sidenav .sidenav-button-mobile {
  display: none;
}
@media only screen and (max-width: 599px) {
  .mobile-menu.sidenav .sidenav-button-mobile {
    display: block;
    margin-top: 48px;
  }
}
.dropdown__col_subheader {
  display: block;
  color: #c5c5c5;
  text-transform: uppercase;
  font-family: "circular-nova-medium", Sans-Serif;
  font-size: 12px;
  letter-spacing: 0.08em;
  line-height: 40px;
  width: 100%;
  padding-left: 8px;
  margin-bottom: 8px;
  margin-top: 0;
}
@media only screen and (max-width: 1600px) {
  .dropdown__col_subheader {
    padding-left: 8px;
    margin-bottom: 5px;
  }
}
.menu.not-top ~ .menu__dropdown {
  top: 80px;
}
.learn-more__link-purple-arrow {
  position: relative;
  color: #111;
}
.learn-more__link-purple-arrow:before {
  content: '';
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  background: url("https://www.altoros.com/images/uploads/arrow-black-5fcf59e223a09.svg") center no-repeat;
  width: 14px;
  height: 8px;
}
.learn-more__link-purple-arrow:hover:before {
  background: url("https://www.altoros.com/images/uploads/arrow-purple-menu-5fcf5c0518976.svg") center no-repeat;
}
.dropdown__bottom-link {
  padding-left: 8px;
  font-size: 16px;
  margin-top: 40px;
}
.dropdown__bottom-link .learn-more__link-purple-arrow {
  color: #5a3cc5;
}
.dropdown__bottom-link .learn-more__link-purple-arrow:before {
  transition: right 0.2s ease;
  background: url("https://www.altoros.com/images/uploads/arrow-purple-menu-5fcf5c0518976.svg") center no-repeat;
}
.dropdown__bottom-link:hover .learn-more__link-purple-arrow:before {
  right: -25px;
}
.dropdown_row {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.dropdown_row .dropdown__col {
  max-width: 100%;
  width: 100%;
  display: flex;
  padding: 0;
  border: none !important;
  align-items: flex-start;
}
@media only screen and (max-width: 1600px) {
  .dropdown_row .dropdown__col {
    flex-direction: column;
  }
}
.dropdown_row .dropdown__col:not(:last-child) {
  margin-bottom: 36px;
}
.dropdown_row .dropdown__col .dropdown__title {
  max-width: 215px;
  width: 100%;
  padding-right: 16px;
}
.dropdown_row .dropdown__col .dropdown__title h4 {
  margin-bottom: 0;
}
@media only screen and (max-width: 1600px) {
  .dropdown_row .dropdown__col .dropdown__title h4 {
    margin-bottom: 12px;
  }
}
.dropdown_row .dropdown__col .dropdown__links {
  display: flex;
  width: 100%;
}
.dropdown_row .dropdown__col .dropdown__links .dropdown__links-block {
  margin-bottom: 0;
  padding: 0 16px;
  width: 25%;
}
.dropdown_row .dropdown__col .dropdown__links .dropdown__links-block:last-child {
  padding-left: 0;
}
@media only screen and (max-width: 1600px) {
  .dropdown_row .dropdown__col .dropdown__links .dropdown__links-block {
    width: 33%;
    padding-left: 0;
  }
}
.link-hoverable {
  position: relative;
}
.link-hoverable:hover .link-hovered {
  visibility: visible;
  opacity: 1;
}
.link-hoverable-mobile {
  display: block;
}
.link-hovered {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 100%;
  top: 0;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 16px rgba(0,0,0,0.1);
  transition: opacity ease 0.4s;
  z-index: 3;
  min-width: 280px;
}
.link-hovered a {
  background-color: transparent !important;
  color: #6e6e6e !important;
}
.link-hovered a:hover {
  color: #5a3cc5 !important;
  background-color: #f2f0fb !important;
}
.sublinks-mobile {
  padding: 5px 5px 5px 25px;
}
.sublinks-mobile a {
  font-size: 16px !important;
  display: block;
  color: #a0a0a0;
  padding: 3px 0;
}
.menu-search-item {
  display: flex;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 1220px) {
  .menu-search-item {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 1200px) {
  .menu-search-item {
    margin-right: 35px;
  }
}
@media only screen and (max-width: 1399px) {
  .menu-search-item {
    display: none;
  }
}
@media only screen and (max-width: 1299px) {
  .menu-search-item--desctp {
    display: none;
  }
}
.menu-search-item.active {
  background-color: transparent;
}
.menu-search-item.active .form_custom {
  padding-left: 30px;
  width: 300px;
}
.menu-search-item .form_custom {
  background-color: transparent;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: -3px;
  height: 62px;
  margin: 0;
  width: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  z-index: 3;
}
.menu-search-item .form_custom .input-field {
  margin: 0;
  width: calc(100% - 24px);
}
.menu-search-item .form_custom .form-btn {
  margin-right: 0;
  width: 24px;
  height: 24px;
  background-color: transparent !important;
}
.menu-search-item .search_btn-menu {
  margin-right: 0 !important;
}
.menu-search-item .search_btn-menu.hided {
  display: none;
}
.menu-search-item--mob {
  display: none;
  margin-right: 20px;
}
@media only screen and (max-width: 1399px) {
  .menu-search-item--mob {
    display: block;
  }
}
@media only screen and (max-width: 1299px) {
  .menu-search-item--mob {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 1220px) {
  .menu-search-item--mob {
    display: block;
  }
}
.gated-block {
  display: flex;
  max-width: 380px;
  margin-bottom: 48px;
  padding-left: 10px;
  padding-top: 2px;
  transition: box-shadow ease 0.2s;
}
.gated-block:hover .play-icon {
  background-color: #5a3cc5;
}
.gated-block:hover .play-icon:after {
  border-left: 15px solid #fff;
  border-top: 7px solid #5a3cc5;
  border-bottom: 7px solid #5a3cc5;
  border-right: 7px solid #5a3cc5;
}
.gated-image {
  position: relative;
  max-height: 144px;
}
.gated-image .play-icon {
  position: absolute;
  display: block;
  width: 48px;
  height: 48px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;
  z-index: 2;
}
.gated-image .play-icon:after {
  content: '';
  display: block;
  width: 1px;
  height: 1px;
  top: 50%;
  left: 18px;
  transform: translate(0, -50%);
  border-left: 15px solid #6e6e6e;
  border-top: 7px solid #fff;
  border-bottom: 7px solid #fff;
  border-right: 7px solid #fff;
  transition: all 0.2s ease;
  position: absolute;
}
.gated-item {
  padding-left: 30px;
  font-size: 16px;
}
.gated-item p {
  font-size: 16px;
  line-height: 24px;
  color: #111;
  margin-bottom: 15px;
}
.gated-item h4 {
  color: #c5c5c5;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.08em;
  margin-bottom: 15px;
}
.gated-item-download img,
.gated-item-download svg {
  margin-right: 10px;
}
.dropdown__block-gated-desktop {
  padding-left: 120px;
}
@media only screen and (max-width: 1864px) {
  .dropdown__block-gated-desktop {
    display: none !important;
  }
}
.dropdown__block-gated-laptop {
  padding-top: 50px;
}
@media (min-width: 1865px) {
  .dropdown__block-gated-laptop {
    display: none !important;
  }
}
.s-header-main {
  width: 100%;
  position: relative;
  padding-top: 344px;
  padding-bottom: 136px;
}
.s-header-main-image-block {
  width: 672px;
  height: 506px;
  position: absolute;
  left: 0;
  bottom: 64px;
  z-index: 6;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.s-header-main-image-block .s-header-main-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("../img/preview-header-video.jpg") center no-repeat;
  background-size: cover;
  z-index: 2;
}
.s-header-main-image-block .s-header-main-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: RGBA(255, 171, 48, 0.9);
  z-index: 2;
  transition: all 0.4s ease;
}
.s-header-main .s-header__btn {
  z-index: 10;
}
.s-header-main .donload-btn__wrap .download-btn {
  min-height: 72px;
}
@media only screen and (max-width: 1200px) {
  .s-header-main {
    padding-top: 200px;
    padding-bottom: 150px;
  }
}
@media only screen and (max-width: 991px) {
  .s-header-main {
    padding-top: 128px;
    padding-bottom: 82px;
    margin-bottom: 126px;
  }
}
@media only screen and (max-width: 699px) {
  .s-header-main {
    padding-bottom: 282px;
    margin-bottom: 0px;
    overflow: visible;
  }
  .s-header-main-image-block {
    width: 100%;
    height: 212px;
  }
}
@media only screen and (max-width: 422px) {
  .s-header-main {
    padding-bottom: 472px;
  }
}
.s-header-main h1 {
  font-size: 68px;
  line-height: 68px;
  margin-bottom: 32px;
  max-width: 840px;
  position: relative;
  z-index: 10;
  pointer-events: none;
}
@media only screen and (max-width: 1200px) {
  .s-header-main h1 {
    max-width: 615px;
  }
}
@media only screen and (max-width: 991px) {
  .s-header-main h1 {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 40px;
    max-width: 408px;
  }
}
.s-header-main .s-header__content {
  position: relative;
}
.s-header-main .s-header-main__animate-block {
  position: absolute;
  height: calc(100% - 112px);
  right: 0;
  bottom: 0;
  width: 55%;
}
@media only screen and (max-width: 1220px) {
  .s-header-main .s-header-main__animate-block {
    height: calc(100% - 80px);
  }
}
@media only screen and (max-width: 699px) {
  .s-header-main .s-header-main__animate-block {
    width: 100%;
    top: 388px;
    height: 276px;
  }
}
.s-header-main .s-header-main__animate-block .animate-block__video-block {
  width: 672px;
  height: 506px;
  position: absolute;
  left: 0;
  bottom: 64px;
  z-index: 6;
  cursor: pointer;
  transition: all 0.4s ease;
}
@media only screen and (max-width: 1200px) {
  .s-header-main .s-header-main__animate-block .animate-block__video-block {
    width: 520px;
    height: 380px;
    left: auto;
    right: 40px;
    bottom: 160px;
  }
}
@media only screen and (max-width: 991px) {
  .s-header-main .s-header-main__animate-block .animate-block__video-block {
    width: 326px;
    height: 246px;
    right: 75px;
    bottom: 68px;
  }
}
@media only screen and (max-width: 699px) {
  .s-header-main .s-header-main__animate-block .animate-block__video-block {
    width: calc(100% - 36px);
    left: 17px;
    right: auto;
    height: 246px;
  }
}
.s-header-main .s-header-main__animate-block .animate-block__video-block .video-overlay__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("../img/preview-header-video.jpg") center no-repeat;
  background-size: cover;
  z-index: 2;
}
.s-header-main .s-header-main__animate-block .animate-block__video-block .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: RGBA(255, 171, 48, 0.9);
  z-index: 2;
  cursor: pointer;
  transition: all 0.4s ease;
}
.s-header-main .s-header-main__animate-block .animate-block__video-block .video-overlay .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.2s ease;
}
.s-header-main .s-header-main__animate-block .animate-block__video-block .video-overlay .play-icon rect {
  transition: all 0.2s ease;
}
.s-header-main .s-header-main__animate-block .animate-block__video-block .video_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}
.s-header-main .s-header-main__animate-block .animate-block__video-block.playing {
  z-index: 11;
  transform: scale(1.1, 1.1);
}
.s-header-main .s-header-main__animate-block .animate-block__video-block.playing .video-overlay__image,
.s-header-main .s-header-main__animate-block .animate-block__video-block.playing .video-overlay {
  display: none;
}
.s-header-main .s-header-main__animate-block .animate-block__video-block:hover {
  transform: scale(1.1, 1.1);
  z-index: 11;
}
.s-header-main .s-header-main__animate-block .animate-block__video-block:hover .play-icon rect {
  fill: #5a3cc5;
}
.s-header-main .s-header-main__animate-block .animate-block__descr-block {
  background-color: RGBA(213, 23, 22, 0.75);
  position: absolute;
  left: 398px;
  bottom: 112px;
  padding: 32px 48px;
  z-index: 8;
  width: 400px;
  transition: all 0.4s ease;
}
@media only screen and (max-width: 1200px) {
  .s-header-main .s-header-main__animate-block .animate-block__descr-block {
    width: 264px;
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .s-header-main .s-header-main__animate-block .animate-block__descr-block {
    width: 205px;
    min-height: 119px;
    padding: 15px 32px;
    right: 52px;
    bottom: 32px;
  }
}
@media only screen and (max-width: 699px) {
  .s-header-main .s-header-main__animate-block .animate-block__descr-block {
    left: 50%;
    transform: translateX(-50%);
  }
}
.s-header-main .s-header-main__animate-block .animate-block__descr-block .animate-block__header {
  font-size: 80px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 88px;
  color: #fff;
  margin-bottom: 0;
  position: relative;
  padding-right: 40px;
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .s-header-main .s-header-main__animate-block .animate-block__descr-block .animate-block__header {
    font-size: 52px;
    line-height: 56px;
    letter-spacing: -0.07px;
    padding-right: 24px;
  }
}
@media only screen and (max-width: 699px) {
  .s-header-main .s-header-main__animate-block .animate-block__descr-block .animate-block__header {
    font-size: 40px;
  }
}
.s-header-main .s-header-main__animate-block .animate-block__descr-block .animate-block__header:after {
  content: '+';
  font-size: 56px;
  line-height: 64px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (max-width: 991px) {
  .s-header-main .s-header-main__animate-block .animate-block__descr-block .animate-block__header:after {
    font-size: 30px;
    line-height: 36px;
  }
}
.s-header-main .s-header-main__animate-block .animate-block__descr-block .animate-block__descr {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.2px;
  line-height: 32px;
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .s-header-main .s-header-main__animate-block .animate-block__descr-block .animate-block__descr {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }
}
.s-header-main .s-header-main__animate-block .animate-block__descr-block:hover {
  background-color: RGBA(213, 23, 22, 1);
  transform: scale(1.1, 1.1);
}
.s-header-main .s-header-main__animate-block .animate-block__photo-block {
  position: absolute;
  bottom: 0;
  left: 80px;
  width: calc(100% - 80px);
  height: 100%;
  z-index: 4;
  overflow: hidden;
  transition: all 0.4s ease;
}
@media only screen and (max-width: 1200px) {
  .s-header-main .s-header-main__animate-block .animate-block__photo-block {
    width: 500px;
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .s-header-main .s-header-main__animate-block .animate-block__photo-block {
    width: 344px;
  }
}
@media only screen and (max-width: 699px) {
  .s-header-main .s-header-main__animate-block .animate-block__photo-block {
    width: 100%;
  }
}
.s-header-main .s-header-main__animate-block .animate-block__photo-block .photo-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: RGBA(90, 60, 197, 0.7);
  transition: all 0.4s ease;
}
.s-header-main .s-header-main__animate-block .animate-block__photo-block .photo-block__bg {
  animation: imageMatrixAnimation 30s infinite;
  animation-play-state: paused;
  height: 100%;
}
@media only screen and (max-width: 699px) {
  .s-header-main .s-header-main__animate-block .animate-block__photo-block .photo-block__bg {
    animation: none;
  }
}
.s-header-main .s-header-main__animate-block .animate-block__photo-block:hover .photo-overlay {
  background-color: transparent;
}
.s-header-main .s-header-main__animate-block .animate-block__photo-block:hover .photo-block__bg {
  animation-timing-function: linear;
  animation-play-state: running;
}
.s-header-main__preheader {
  margin-top: 0;
  margin-bottom: 0;
}
.s-header-main__preheader .container {
  padding-top: 88px;
  padding-bottom: 88px;
  background-color: #fff;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .s-header-main__preheader .container {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
@media only screen and (max-width: 699px) {
  .s-header-main__preheader .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.s-header-main__preheader .container:before {
  content: '';
  display: block;
  width: 1000px;
  height: 100%;
  left: -998px;
  top: 0;
  background-color: #fff;
  position: absolute;
}
.s-header-main__preheader .container p {
  font-size: 34px;
  font-weight: 300;
  letter-spacing: -0.1px;
  line-height: 48px;
  color: #111;
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .s-header-main__preheader .container p {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 0;
  }
}
.s-offer.only-top .s-offer__top {
  padding-bottom: 0;
}
.s-offer.only-top .s-offer__top .s-offer__block {
  margin-bottom: 0;
}
.s-offer.only-top .s-offer__top .s-offer__block:not(:last-child) {
  margin-bottom: 32px;
}
.s-offer.to-right .container:after {
  left: auto;
  right: -1000px;
}
.s-offer.to-right .container:before {
  right: auto;
  left: -96px;
}
.s-offer.pb-0 .container {
  padding-bottom: 0;
}
.s-offer.pt-0 .container {
  padding-top: 0;
}
.s-offer.no-bg .container {
  padding-bottom: 144px;
  background-color: transparent;
}
.s-offer.no-bg .container:after {
  display: none;
}
.s-offer .container {
  padding-bottom: calc(72px - 32px);
  background-color: #fff;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .s-offer .container {
    padding-bottom: calc(64px - 32px);
  }
}
@media only screen and (max-width: 600px) {
  .s-offer .container {
    padding-bottom: calc(48px - 32px);
  }
}
.s-offer .container:after {
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 1000px;
  top: 0;
  left: -1000px;
  background-color: #fff;
}
.s-offer .s-offer__title {
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .s-offer .s-offer__title {
    margin-bottom: 24px;
  }
}
.s-offer .s-offer__pretitle {
  color: #111;
}
.s-offer .s-offer__num {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.s-offer .s-offer__num .s-offer__num-item {
  font-size: 80px;
  line-height: 60px;
  color: #111;
  font-family: 'circular-nova-medium';
  letter-spacing: -1px;
  position: relative;
  margin-bottom: 16px;
  display: inline-flex;
  align-items: flex-start;
}
@media only screen and (max-width: 991px) {
  .s-offer .s-offer__num .s-offer__num-item {
    font-size: 52px;
    letter-spacing: -0.07px;
    line-height: 58px;
    margin-bottom: 8px;
  }
}
.s-offer .s-offer__num .s-offer__num-item .s-offer__num-item_after {
  font-size: 56px;
  color: #111;
  font-family: "circular-nova-medium";
  line-height: 40px;
  margin-left: 8px;
}
@media only screen and (max-width: 991px) {
  .s-offer .s-offer__num .s-offer__num-item .s-offer__num-item_after {
    font-size: 30px;
    line-height: 32px;
    margin-left: 4px;
  }
}
.s-offer .s-offer__num-text {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.2px;
  color: #6e6e6e;
  margin-top: -3px;
}
@media only screen and (max-width: 991px) {
  .s-offer .s-offer__num-text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }
}
.s-offer .s-offer__num-header {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.2px;
  color: #111;
  margin-bottom: 11px;
}
@media only screen and (max-width: 991px) {
  .s-offer .s-offer__num-header {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }
}
.s-offer .s-offer__preheader {
  color: #111;
}
.s-offer .s-offer__text {
  margin-bottom: 32px;
}
.s-offer .s-offer__text:last-child {
  margin-bottom: 0px;
}
.s-offer .s-offer__text p {
  letter-spacing: -0.2px;
}
.s-offer .s-offer__text p:last-child {
  margin-bottom: 0px;
}
.s-offer .s-offer__line {
  border-top: 2px solid #e0dfe0;
}
.s-offer .s-offer__buttom {
  padding-top: 64px;
}
@media only screen and (max-width: 991px) {
  .s-offer .s-offer__buttom {
    padding-top: 47px;
  }
}
.s-offer .s-offer__top {
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
}
.s-offer .s-offer__block {
  margin-bottom: 32px;
  width: 25% !important;
}
@media only screen and (max-width: 767px) {
  .s-offer .s-offer__block {
    width: 50% !important;
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 600px) {
  .s-offer .s-offer__block {
    width: 100% !important;
  }
}
@media only screen and (max-width: 600px) {
  .s-offer .s-offer__content .s-offer__buttom {
    padding-top: 0px;
  }
}
.s-offer.red-line .container:before {
  position: absolute;
  content: '';
  display: block;
  height: 70%;
  width: 96px;
  background-color: #d51716;
  right: -96px;
  bottom: 0;
}
.s-offer.white-bg {
  background-color: #fff;
}
.s-offer.offer-custom-paddings .container {
  padding-top: 88px;
}
@media only screen and (max-width: 991px) {
  .s-offer.offer-custom-paddings .container {
    padding-top: 64px;
  }
}
@media only screen and (max-width: 600px) {
  .s-offer.offer-custom-paddings .container {
    padding-top: 48px;
  }
}
.s-offer.s-offer_v2.big-padding {
  padding-bottom: 88px;
}
@media only screen and (max-width: 991px) {
  .s-offer.s-offer_v2.big-padding {
    padding-bottom: 64px;
  }
}
.s-offer.s-offer_v2 .row {
  position: relative;
}
.s-offer.s-offer_v2 .s-offer__aftertext {
  position: absolute;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #a0a0a0;
  bottom: -50px;
  left: 0;
}
@media only screen and (max-width: 991px) {
  .s-offer.s-offer_v2 .s-offer__aftertext {
    bottom: -40px;
  }
}
.s-services.s-services_v3 {
  margin-bottom: 0;
}
.s-services.s-services_v3.mb-144 {
  margin-bottom: 144px;
}
.s-services.s-services_v3 .btn__wrap {
  display: inline-block;
  margin-top: 32px;
}
.s-services.s-services_v3 .s-services-header {
  border-bottom: 2px solid #e0dfe0;
}
.s-services.s-services_v3 .s-services-header .s-services-header__block-content_text {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
}
@media only screen and (max-width: 991px) {
  .s-services.s-services_v3 .s-services-header .s-services-header__block-content_text {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .s-services.s-services_v3 .s-services-content-desktop {
    display: none;
  }
}
.s-services.s-services_v3 .s-services-content-tablet {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .s-services.s-services_v3 .s-services-content-tablet {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .s-services.s-services_v3 .s-services-content-tablet {
    display: none;
  }
}
.s-services.s-services_v3 .s-services-content-mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .s-services.s-services_v3 .s-services-content-mobile {
    display: block;
  }
}
.s-services.s-services_v3 .s-services__block-header {
  margin-top: 64px;
}
.s-services.s-services_v3 h4 {
  margin-top: 32px;
}
.s-services.s-services_v3 .s-services__item {
  margin-top: 64px;
}
@media only screen and (max-width: 600px) {
  .s-services.s-services_v3 .s-services__item {
    margin-top: 32px;
  }
}
.s-services.s-services_v3 .s-services__item .s-services__item-image {
  max-width: 64px;
  max-height: 64px;
}
.s-services.s-services_v3 .s-services-content .row:last-child .col:nth-last-child(2) .s-services__item:last-child {
  padding-bottom: 0;
}
.s-services.s-services_v3 .s-services__item-desc p {
  margin-bottom: 24px;
}
.s-services.s-services_v3 .s-services__item-desc p:last-child {
  margin-bottom: 0;
}
.s-services.s-services_v3 .s-services-content-tablet .s-services__blocks {
  margin: 47px 0 0;
  border: none;
  box-shadow: none;
}
.s-services.s-services_v3 .s-services-content-tablet .indicator {
  display: none;
}
.s-services.s-services_v3 .s-services-content-tablet .s-services__block {
  list-style: none;
}
.s-services.s-services_v3 .s-services-content-tablet .s-services__block.active .s-services__block-header h3 {
  color: #111;
}
.s-services.s-services_v3 .s-services-content-tablet .s-services__block-header {
  padding: 8px 0;
  margin-top: 0;
  background-color: transparent;
  border-bottom: none;
  outline: none;
  cursor: pointer;
}
.s-services.s-services_v3 .s-services-content-tablet .s-services__block-header h3 {
  margin: 0;
  color: #a0a0a0;
  font-family: "circular-nova-regular";
}
.s-services.s-services_v3 .s-services-content-tablet .s-services__block-desc {
  padding: 8px 0 32px;
  border-bottom: none;
}
.s-services.s-services_v3 .s-services-content-tablet .s-services__block-desc p {
  margin-bottom: 0px;
}
.s-services.s-services_v3 .s-services-content-mobile h3 {
  margin-top: 0;
  margin-bottom: 0;
  color: #a0a0a0;
}
.s-services.s-services_v3 .s-services-content-mobile .s-services__block-header-img {
  transition: transform 0.2s;
}
.s-services.s-services_v3 .s-services-content-mobile .indicator {
  display: none;
}
.s-services.s-services_v3 .s-services-content-mobile .s-services__blocks {
  margin: 0;
  margin-top: 63px;
}
@media only screen and (max-width: 600px) {
  .s-services.s-services_v3 .s-services-content-mobile .s-services__blocks {
    margin-top: 32px;
  }
}
.s-services.s-services_v3 .s-services-content-mobile .collapsible {
  border: none;
  box-shadow: none;
}
.s-services.s-services_v3 .s-services-content-mobile .collapsible-body {
  padding: 0;
  border: none;
}
.s-services.s-services_v3 .s-services-content-mobile .s-services__block {
  list-style: none;
}
.s-services.s-services_v3 .s-services-content-mobile .s-services__block.active h3 {
  color: #5a3cc5;
}
.s-services.s-services_v3 .s-services-content-mobile .s-services__block.active .s-services__block-header-img {
  transform: rotate(-180deg);
}
.s-services.s-services_v3 .s-services-content-mobile .s-services__block.active .s-services__block-header-img use {
  fill: #111;
}
.s-services.s-services_v3 .s-services-content-mobile .s-services__block-header {
  margin-top: 0;
  margin-bottom: 16px;
  padding: 0;
  background-color: transparent;
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.s-services.s-services_v3 .s-services-content-mobile .s-services__items {
  margin-bottom: 15px;
}
.s-services.s-services_v3 .without-padding .s-services__item {
  padding-bottom: 0;
}
.s-services.s-services_v3 .without-padding .custom__list-check {
  margin-bottom: 0;
}
.s-services.bg-light {
  background-color: #fff;
}
@media only screen and (max-width: 600px) {
  .s-services.bg-light .s-services-header .s-services-header__block:nth-child(1) {
    border-bottom: none;
  }
}
.s-services.bg-light .s-services-header .s-services-header__block .s-services-header__block-content {
  border-bottom: none;
}
.s-services.bg-gradient {
  position: relative;
  z-index: 10;
}
.s-services.bg-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 550px;
  height: 385px;
  background-color: #f0e9fd;
  filter: blur(200px);
  z-index: -1;
}
.s-services.bg-gradient .s-services-header {
  border-bottom: none;
}
.s-services.bg-gradient .s-services-header .s-services-header__block-content {
  max-width: 640px;
}
.s-services .s-services__btn-wrap {
  margin-top: 96px;
}
.s-services .s-services__btn-bordered a {
  border-width: 2px !important;
}
.s-services .s-services__btn-bordered a span {
  font-family: "circular-nova-medium";
}
.s-services .s-services-content__text-wrap.services_link {
  padding-bottom: 40px;
}
.s-services .s-services-header {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid #e0dfe0;
}
.s-services .s-services-header__image {
  text-align: center;
}
.s-services .s-services-header__image img {
  display: inline-block;
}
.s-services .s-services-header .s-services-header__block {
  display: flex;
}
.s-services .s-services-header .s-services-header__block .s-services-header__block-content_text {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
}
@media only screen and (max-width: 991px) {
  .s-services .s-services-header .s-services-header__block .s-services-header__block-content_text {
    margin-bottom: 0;
  }
}
.s-services .s-services-header .s-services-header__block .s-services-header__block-content {
  width: 100%;
}
.s-services .s-services-header .s-services-header__block .s-services-header__block-content h2 {
  margin-bottom: 32px;
}
@media only screen and (max-width: 991px) {
  .s-services .s-services-header .s-services-header__block .s-services-header__block-content h2 {
    margin-bottom: 24px;
  }
}
.s-services .s-services-content {
  display: flex;
  flex-wrap: wrap;
}
.s-services .s-services-content .custom__list-check li {
  margin-bottom: 8px;
}
.s-services .s-services-content .s-services-content__block {
  margin-top: 64px;
  position: relative;
}
.s-services .s-services-content .s-services-content__block p {
  margin-bottom: 0;
}
.s-services .s-services-content .s-services-content__block .s-services-content__image-wrap {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.s-services .s-services-content .s-services-content__block .s-services-content__image-wrap .s-services-content__img {
  max-height: 100%;
  flex: 1;
}
.s-services .s-services-content .s-services-content__block .learn-more__link {
  position: absolute;
  bottom: -10px;
  left: 15px;
}
@media only screen and (max-width: 1200px) {
  .s-services .container .s-services-header .s-services-header__block .s-services-header__block-content p {
    margin-bottom: 47px;
  }
}
@media only screen and (max-width: 991px) {
  .s-services .container .s-services-header {
    flex-wrap: wrap;
  }
  .s-services .container .s-services-header h2 {
    padding-right: 8px;
  }
  .s-services .container .s-services-content {
    flex-wrap: wrap;
  }
  .s-services .container .s-services-content .s-services-content__block {
    margin-top: 48px;
    padding-bottom: 19px;
    display: flex;
  }
  .s-services .container .s-services-content .s-services-content__block p {
    margin-bottom: 26px;
  }
  .s-services .container .s-services-content .s-services-content__block h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  .s-services .container .s-services-content .s-services-content__block .s-services-content__text-wrap {
    width: calc(100% - 120px);
  }
  .s-services .container .s-services-content .s-services-content__block .s-services-content__image-wrap {
    margin-right: 56px;
  }
  .s-services .container .s-services-content .s-services-content__block .s-services-content__image-wrap .s-services-content__img {
    max-width: 100%;
  }
  .s-services .container .s-services-content .s-services-content__block .learn-more__link {
    position: relative;
    left: 0;
  }
  .s-services .container .s-services-content .s-services-content__block p {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .s-services .container .s-services-content .s-services-content__block .s-services-content__image-wrap {
    margin-right: 24px;
  }
}
@media only screen and (max-width: 679px) {
  .s-services .container .s-services-content {
    flex-direction: column;
  }
  .s-services .container .s-services-content .s-services-content__block {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .s-services .container .s-services-header .s-services-header__block .s-services-header__block-content h2 {
    padding-right: 0;
    margin-bottom: 24px;
  }
  .s-services .container .s-services-header .s-services-header__block:first-child .s-services-header__block-content {
    border-bottom: none;
  }
  .s-services .container .s-services-content .s-services-content__block {
    display: block;
    margin-bottom: 0px;
  }
  .s-services .container .s-services-content .s-services-content__block:nth-last-child(1),
  .s-services .container .s-services-content .s-services-content__block:nth-last-child(2),
  .s-services .container .s-services-content .s-services-content__block:nth-last-child(3) {
    margin-bottom: 0;
  }
  .s-services .container .s-services-content .s-services-content__block .s-services-content__text-wrap {
    width: 100%;
  }
  .s-services .container .s-services-content .s-services-content__block p {
    margin-bottom: 16px;
  }
  .s-services .container .s-services-content .s-services-content__block h3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .s-services .container .s-services-content .s-services-content__block .s-services-content__image-wrap .s-services-content__img {
    width: auto;
  }
}
.image_services_static,
.image_hide {
  display: none !important;
}
.image_services_show {
  display: flex !important;
}
.image_services_static img {
  width: auto !important;
  height: auto !important;
}
.s-services-advantages {
  margin: 144px 0 56px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 64px;
  gap: 64px;
}
@media only screen and (max-width: 991px) {
  .s-services-advantages {
    gap: 0;
    margin: 48px 0;
    grid-template-columns: 1fr;
    row-gap: 32px;
  }
}
@media only screen and (max-width: 768px) {
  .s-services-advantages {
    padding: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .s-services-advantages {
    background-color: transparent;
    padding: 0;
  }
}
.s-services-advantages__item {
  position: relative;
}
.s-services-advantages__item:nth-child(odd) {
  padding-right: 32px;
  margin-right: 0;
}
@media only screen and (max-width: 991px) {
  .s-services-advantages__item:nth-child(odd) {
    padding-right: 0;
    margin-right: 0;
  }
}
.s-services-advantages__item:nth-child(odd)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #e0dfe0;
}
@media only screen and (max-width: 991px) {
  .s-services-advantages__item:nth-child(odd)::after {
    display: none;
  }
}
.s-services.s-services-advantages-wrap.white-bg {
  background-color: #fff;
}
.s-services.s-services-advantages-wrap.white-bg .container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.s-services.s-services-advantages-wrap.white-bg .s-services-advantages {
  background-color: #f7f7f7;
  margin-top: 40px;
}
@media only screen and (max-width: 768px) {
  .s-services.s-services-advantages-wrap.white-bg .s-services-advantages {
    margin-top: 0;
  }
  .s-services.s-services-advantages-wrap.white-bg .s-services-advantages .s-services-advantages__item {
    padding: 32px 32px 0 32px !important;
  }
}
.s-services-slider .container {
  position: relative;
}
.s-services-slider .container:before {
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 1500px;
  background-color: #f7f7f7;
  left: -1500px;
  bottom: 0;
  z-index: 1;
}
.s-services-slider.bg-light {
  background-color: #fff;
}
.s-services-slider.bg-light .s-services__slider-wrap:before {
  background-color: #fff;
}
.s-services-slider.black-text .container::before {
  background-color: #fff;
}
.s-services-slider.black-text .s-services__slider-wrap::before {
  display: none;
}
.s-services-slider.black-text .s-services__slider-wrap .s-services__slide {
  max-width: 512px;
}
.s-services-slider.black-text .s-services__slider-wrap .s-services__slide p {
  font-family: "circular-nova-medium", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.3px;
  color: #111;
}
.s-services-slider .s-services__title {
  margin-bottom: 32px;
}
.s-services-slider .s-services__desc {
  max-width: 832px;
  margin-bottom: 0px;
}
.s-services-slider .s-services__links {
  display: flex;
  flex-wrap: wrap;
  margin-top: 69px;
}
.s-services-slider .s-services__links .s-services__link {
  max-width: 224px;
  margin-bottom: 45px;
  padding-right: 64px;
}
@media only screen and (max-width: 1200px) {
  .s-services-slider .s-services__links .s-services__link {
    padding-right: 24px;
  }
}
.s-services-slider .s-services__links .s-services__link-wide {
  max-width: 320px;
}
.s-services-slider .s-services__links .s-services__link:not(:last-child) {
  flex: 1;
}
@media only screen and (max-width: 991px) {
  .s-services-slider .s-services__links .s-services__link {
    padding-right: 32px;
    max-width: 176px;
  }
}
@media only screen and (max-width: 767px) {
  .s-services-slider .s-services__links .s-services__link {
    max-width: inherit;
    width: 33.3%;
  }
}
@media only screen and (max-width: 600px) {
  .s-services-slider .s-services__links .s-services__link {
    width: 50%;
  }
}
.s-services-slider .s-services__links .s-services__link-icon {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.s-services-slider .s-services__links .s-services__link-icon:hover svg,
.s-services-slider .s-services__links .s-services__link-icon:hover img {
  transform: scale(1.1, 1.1);
}
.s-services-slider .s-services__links .s-services__link-icon svg,
.s-services-slider .s-services__links .s-services__link-icon img {
  width: 64px;
  height: 64px;
  transition: transform 0.2s ease;
}
.s-services-slider .s-services__links .s-services__link-title {
  margin-bottom: 0;
}
.s-services-slider .s-services__slider {
  margin-top: 83px;
  position: relative;
}
@media only screen and (max-width: 1312px) {
  .s-services-slider .s-services__slider {
    width: 100vw;
  }
}
@media only screen and (max-width: 991px) {
  .s-services-slider .s-services__slider {
    margin-top: 51px;
  }
}
@media only screen and (max-width: 512px) {
  .s-services-slider .s-services__slider {
    width: auto;
  }
}
.s-services-slider .s-services__slider .s-services__slide {
  height: auto;
}
.s-services-slider .s-services__slider .s-services__slide:focus {
  outline: none;
  border: none;
}
.s-services-slider .s-services__slider .s-services__slide p {
  margin-bottom: 0px;
}
.s-services-slider .s-services__slider-arrows {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
}
.s-services-slider .s-services__slider-wrap {
  padding-right: 20px;
  position: relative;
}
.s-services-slider .s-services__slider-wrap:before {
  position: absolute;
  content: '';
  width: 1500px;
  height: 100%;
  bottom: 0;
  right: -1200px;
  background: linear-gradient(to right, rgba(247,247,247,0) 0%, #f7f7f7 40%, #f7f7f7 100%);
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .s-services-slider .s-services__slider-wrap {
    padding-right: 0;
  }
}
.s-services-slider .s-services__slider-wrap .slick-list {
  padding-top: 30px;
  overflow: visible;
}
@media only screen and (max-width: 600px) {
  .s-services-slider .s-services__slider-wrap .slick-list {
    overflow: hidden;
  }
}
.s-services-slider .s-services__slider-wrap .s-services__slide {
  max-width: 448px;
  width: 100%;
}
.s-services-slider .s-services__slider-wrap .s-services__slide:not(:last-child) {
  margin-right: 128px;
}
@media only screen and (max-width: 1200px) {
  .s-services-slider .s-services__slider-wrap .s-services__slide:not(:last-child) {
    margin-right: 70px;
  }
}
@media only screen and (max-width: 991px) {
  .s-services-slider .s-services__slider-wrap .s-services__slide:not(:last-child) {
    margin-right: 120px;
  }
}
@media only screen and (max-width: 512px) {
  .s-services-slider .s-services__slider-wrap .s-services__slide:not(:last-child) {
    margin-right: 0;
  }
}
.s-services-slider .s-services__slider-wrap .s-services__slide .s-services__slide-num {
  top: -30px;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  height: 32px;
  color: #5a3cc5;
  font-family: 'circular-nova-medium';
  width: 30px;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease-in;
}
@media only screen and (max-width: 991px) {
  .s-services-slider .s-services__slider-wrap .s-services__slide .s-services__slide-num {
    font-size: 18px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .s-services-slider .s-services__slider-wrap .s-services__slide .s-services__slide-num {
    transform: translateX(0px);
  }
}
.s-services-slider .s-services__slider-wrap .s-services__slide.slick-current .s-services__slide-num {
  transform: translateX(83px);
}
.s-services-slider .s-services-slider__more-link {
  margin-top: 32px;
}
.s-services-slider.s-services-slider-color .container {
  background-color: #fff;
  position: relative;
}
.s-services-slider.s-services-slider-color .container:before {
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 1500px;
  background-color: #f7f7f7;
  left: -1500px;
  bottom: 0;
  z-index: 1;
}
.s-services-slider.s-services-slider-color .container:after {
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 1000px;
  background-color: #fff;
  right: -1000px;
  bottom: 0;
  z-index: -1;
}
.s-services-slider.s-services-slider-color .container__line {
  position: absolute;
  content: '';
  display: block;
  height: 70%;
  width: 96px;
  background-color: #5a3cc5;
  left: -96px;
  bottom: 0;
  z-index: 1;
}
.s-services-slider.s-services-slider-color .s-services__slider-wrap:after {
  content: '';
  position: absolute;
  left: -192px;
  width: 192px;
  background-color: #fff;
  height: calc(100% - 32px);
  bottom: 0;
}
.s-services-slider.s-services-slider-color .s-services__slider-wrap:before {
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, #fff 40%, #fff 100%);
}
.s-services-slider.s-services-slider-color:before {
  background-color: #fff;
  box-shadow: 0 0 30px 0 #fff;
}
.s-why.bg-grey .container {
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
}
.s-why.bg-grey .container:after {
  display: none;
}
.s-why.padding-true {
  padding-top: 88px;
  padding-bottom: 88px;
}
@media only screen and (max-width: 991px) {
  .s-why.padding-true {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
@media only screen and (max-width: 600px) {
  .s-why.padding-true {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.s-why.s-why-circles .container {
  background-color: transparent;
}
.s-why.s-why-circles .container:after {
  display: none;
}
.s-why.s-why-circles .s-why-header .s-why-header__block {
  border-bottom: 0;
}
.s-why.s-why-circles h2 {
  margin-bottom: 0;
}
@media only screen and (max-width: 600px) {
  .s-why.s-why-circles h2 {
    margin-bottom: 24px;
  }
}
.s-why.s-why-circles .s-why-collapse .collapsible li .collapsible-body p {
  margin-bottom: 32px;
}
@media only screen and (max-width: 991px) {
  .s-why.s-why-circles .s-why-collapse .collapsible li .collapsible-body p {
    margin-bottom: 24px;
  }
}
.s-why.s-why-circles .s-why-tabs__nav_circle .s-why-tab,
.s-why.s-why-circles .s-why-tabs__nav .s-why-tab {
  position: relative;
  padding-left: 40px;
}
.s-why.s-why-circles .s-why-tabs__nav_circle .s-why-tab:last-child:after,
.s-why.s-why-circles .s-why-tabs__nav .s-why-tab:last-child:after {
  display: none;
}
.s-why.s-why-circles .s-why-tabs__nav_circle .s-why-tab.slick-current:before,
.s-why.s-why-circles .s-why-tabs__nav .s-why-tab.slick-current:before {
  border: 8px solid #5a3cc5;
}
.s-why.s-why-circles .s-why-tabs__nav_circle .s-why-tab:before,
.s-why.s-why-circles .s-why-tabs__nav .s-why-tab:before {
  position: absolute;
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #e0dfe0;
  box-shadow: 0 0 0 5px #f7f7f7;
  background-color: #f7f7f7;
  left: 0;
  top: 8px;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 991px) {
  .s-why.s-why-circles .s-why-tabs__nav_circle .s-why-tab:before,
  .s-why.s-why-circles .s-why-tabs__nav .s-why-tab:before {
    top: 4px;
  }
}
.s-why.s-why-circles .s-why-tabs__nav_circle .s-why-tab:after,
.s-why.s-why-circles .s-why-tabs__nav .s-why-tab:after {
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 2px;
  background-color: #e0dfe0;
  left: 11px;
  top: 34px;
}
.s-why .btn__wrap {
  margin-top: 32px;
}
.s-why.full-width .container:before {
  content: '';
  display: block;
  height: 100%;
  width: 1000px;
  left: -998px;
  top: 0;
  background-color: #fff;
  position: absolute;
}
.s-why.no-line .s-why-header .s-why-header__block {
  border-bottom: 0;
}
.s-why.no-line h2 {
  margin-bottom: 0;
}
@media only screen and (max-width: 600px) {
  .s-why.no-line h2 {
    margin-bottom: 24px;
  }
}
.s-why .learn-more__links-wrap .learn-more__link {
  margin-right: 30px;
}
.s-why .learn-more__links-wrap .learn-more__link:last-child {
  margin-right: 0;
}
.s-why .container {
  background-color: #fff;
}
.s-why h3 {
  margin-top: 0;
  margin-bottom: 15px;
}
.s-why .s-why-header {
  display: flex;
}
.s-why .s-why-header .s-why-header__block {
  display: flex;
  border-bottom: 2px solid #e0dfe0;
}
@media only screen and (max-width: 600px) {
  .s-why .s-why-header .s-why-header__block:nth-child(1) {
    border-bottom: none;
  }
}
.s-why .s-why-header .s-why-header__block-content {
  width: 100%;
}
.s-why .s-why-header .s-why-header__block-content_text {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
}
@media only screen and (max-width: 991px) {
  .s-why .s-why-header .s-why-header__block-content_text {
    margin-bottom: 0;
  }
}
.s-why .s-why-tabs__nav_circle,
.s-why .s-why-tabs__nav {
  height: auto;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  overflow: inherit;
}
.s-why .s-why-tabs__nav_circle .slick-track,
.s-why .s-why-tabs__nav .slick-track {
  transform: none !important;
  width: 100% !important;
}
.s-why .s-why-tabs__nav_circle .slick-list,
.s-why .s-why-tabs__nav .slick-list {
  padding: 0 !important;
  height: auto !important;
}
.s-why .s-why-tabs__nav_circle .s-why-tab,
.s-why .s-why-tabs__nav .s-why-tab {
  height: auto;
  text-align: left;
  width: 100%;
  margin-bottom: 13px;
  position: relative;
  cursor: pointer;
}
.s-why .s-why-tabs__nav_circle .s-why-tab:last-child,
.s-why .s-why-tabs__nav .s-why-tab:last-child {
  margin-bottom: 0px;
}
.s-why .s-why-tabs__nav_circle .s-why-tab span,
.s-why .s-why-tabs__nav .s-why-tab span {
  color: #a0a0a0;
  font-size: 28px;
  user-select: none;
  letter-spacing: -0.1px;
  line-height: 43px;
  padding: 0;
  text-transform: none;
  background-color: transparent !important;
  overflow: inherit;
  transition: all 0.2s ease;
  height: auto;
}
.s-why .s-why-tabs__nav_circle .s-why-tab span:hover,
.s-why .s-why-tabs__nav .s-why-tab span:hover {
  color: #6e6e6e;
}
.s-why .s-why-tabs__nav_circle .s-why-tab.slick-current span,
.s-why .s-why-tabs__nav .s-why-tab.slick-current span {
  color: #5a3cc5;
}
.s-why .s-why-tabs {
  padding-top: 72px;
}
@media only screen and (max-width: 991px) {
  .s-why .s-why-tabs {
    padding-top: 32px;
  }
}
.s-why .s-why-tabs .s-why-tabs__result_circle,
.s-why .s-why-tabs .s-why-tabs__result {
  overflow: hidden;
}
.s-why .s-why-tabs .tab__content {
  width: 100%;
  display: block !important;
  overflow: hidden;
  padding-left: 5px;
}
.s-why .s-why-tabs .tab__content .tab__content-inner {
  transition: transform 0.6s ease;
  max-width: 544px;
  transform: translateX(100%);
}
.s-why .s-why-tabs .tab__content .tab__content-inner p strong {
  color: #111;
  font-family: 'circular-nova-medium';
}
.s-why .s-why-tabs .tab__content.slick-current .tab__content-inner {
  height: auto;
  transform: translateX(0);
}
.s-why .s-why-tabs .tab__content p {
  margin-bottom: 24px;
}
.s-why .s-why-tabs .tab__content p:last-child {
  margin-bottom: 32px;
}
@media only screen and (max-width: 1500px) {
  .s-why .s-why-tabs .tab__content p:last-child {
    margin-bottom: 26px;
  }
}
@media (max-width: 1299px) {
  .s-why .s-why-tabs .tab-image {
    max-width: 100%;
  }
}
.s-why .s-why-tabs-bigtext {
  font-size: 26px;
  margin-top: 20px;
}
.s-why .s-why-tabs-bigtext-mobile {
  margin-top: 10px;
}
.s-why .tab__subtext {
  font-weight: 500;
  color: #111;
  margin-bottom: 0;
}
.s-why .tab__subtext span {
  color: #6e6e6e;
}
.s-why .tab__num-block {
  margin-top: 48px;
}
@media only screen and (max-width: 991px) {
  .s-why .tab__num-block {
    margin-top: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .s-why .tab__num-block {
    max-width: 232px;
  }
}
.s-why .tab__num-block .tab__num {
  font-size: 80px;
  letter-spacing: -1px;
  line-height: 88px;
  font-family: 'circular-nova-medium';
}
@media only screen and (max-width: 991px) {
  .s-why .tab__num-block .tab__num {
    font-size: 52px;
    line-height: 64px;
    letter-spacing: -0.65px;
  }
}
.s-why .tab__num-block .tab__num span {
  font-size: 56px;
  display: inline-block;
  margin-left: 5px;
}
@media only screen and (max-width: 991px) {
  .s-why .tab__num-block .tab__num span {
    font-size: 36px;
  }
}
.s-why .tab__num-block .tab__num-text {
  color: #808080;
  position: relative;
  top: -8px;
  font-size: 20px;
  letter-spacing: -0.2px;
  line-height: 32px;
  max-width: 280px;
}
@media only screen and (max-width: 991px) {
  .s-why .tab__num-block .tab__num-text {
    top: -2px;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }
}
.s-why .s-why-collapse .collapsible {
  border: none;
  box-shadow: none;
  margin-top: 36px;
  margin-bottom: 0;
}
@media only screen and (max-width: 600px) {
  .s-why .s-why-collapse .collapsible {
    margin-top: 8px;
  }
}
.s-why .s-why-collapse .collapsible li .collapsible-header {
  padding: 12px 0;
  color: #a0a0a0;
  font-size: 24px;
  letter-spacing: -0.26px;
  line-height: 32px;
  transition: all 0.2s ease;
  border: none;
  background-color: transparent;
  outline: none;
  padding-right: 30px;
  position: relative;
}
.s-why .s-why-collapse .collapsible li .collapsible-header h3 {
  margin-bottom: 0;
  margin-top: 0;
}
.s-why .s-why-collapse .collapsible li .collapsible-header:after {
  position: absolute;
  content: '';
  top: 12px;
  right: 0px;
  width: 24px;
  height: 24px;
  background-image: url("/assets/img/list-arrow-grey.svg");
  background-position: 50% 50%;
}
.s-why .s-why-collapse .collapsible li.active .collapsible-header {
  color: #111;
}
.s-why .s-why-collapse .collapsible li.active .collapsible-header:after {
  background-image: url("/assets/img/list-arrow-purple.svg");
}
.s-why .s-why-collapse .collapsible li .collapsible-body {
  padding: 0 0 48px;
  border: none;
}
@media only screen and (max-width: 600px) {
  .s-why .s-why-collapse .collapsible li .collapsible-body {
    padding-top: 18px;
  }
}
.s-why .s-why-collapse .collapsible li .collapsible-body p {
  margin-bottom: 16px;
}
.s-why .s-why-collapse .collapsible li .collapsible-body p strong {
  color: #111;
  font-family: 'circular-nova-medium';
}
@media only screen and (max-width: 991px) {
  .s-why .s-why-tabs .s-why-tab {
    margin-bottom: 16px;
  }
  .s-why .s-why-tabs .s-why-tab span {
    font-size: 22px;
    line-height: 32px;
  }
  .s-why .tab__content h3 {
    font-size: 20px;
    letter-spacing: -0.06px;
    line-height: 30px;
    margin-bottom: 24px;
  }
  .s-why .tabs {
    padding-top: 40px;
  }
  .s-why .tabs .tab {
    margin-bottom: 16px;
  }
  .s-why .tabs .tab a {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.26px;
    line-height: 32px;
  }
}
@media only screen and (min-width: 601px) {
  .s-why .s-why-collapse {
    display: none;
  }
  .s-why-images-mobile {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .s-why .s-why-collapse .collapsible-body h3 {
    font-size: 22px;
    letter-spacing: -0.2px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  .s-why .s-why-collapse .collapsible-body .tab__num-block {
    margin-top: 64px;
  }
  .s-why .s-why-collapse .collapsible li.active .collapsible-header h3 {
    color: #5a3cc5;
  }
  .s-why .s-why-header {
    flex-wrap: wrap;
  }
  .s-why .s-why-header .s-why-header__block:first-child .s-why-header__block-content {
    border-bottom: none;
  }
  .s-why .s-why-header .s-why-header__block-content p {
    margin-bottom: 0;
  }
  .s-why .s-why-tabs {
    display: none;
  }
  .s-why-images-mobile-item:not(:last-child) {
    padding-bottom: 5px;
  }
  .s-why-images-mobile-image img {
    width: 100%;
  }
}
.s-cases.s-cases-vue .s-cases__blocks-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: calc(100% + 48px);
  margin-left: -24px;
}
.s-cases.s-cases-vue .custom-slider__num {
  top: -32px;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  height: 32px;
  color: #5a3cc5;
  font-family: 'circular-nova-medium', Sans-Serif;
  width: 30px;
  text-align: center;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .s-cases.s-cases-vue .custom-slider__num {
    display: none;
  }
}
.s-cases.s-cases-vue .s-cases__block {
  margin-bottom: 50px;
  width: calc(100% / 3 - 48px);
  margin-left: 24px;
  margin-right: 24px;
}
@media only screen and (max-width: 992px) {
  .s-cases.s-cases-vue .s-cases__block {
    width: calc(100% / 2 - 48px);
  }
}
@media only screen and (max-width: 600px) {
  .s-cases.s-cases-vue .s-cases__block {
    width: calc(100% / 1 - 48px);
  }
}
.s-cases.s-cases-vue.gradient-bg {
  position: relative;
  padding: 88px 0 6px;
  margin-top: 0;
  margin-bottom: 0;
}
.s-cases.s-cases-vue.gradient-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 491px;
  height: 381px;
  background-color: #f0e9fd;
  filter: blur(200px);
}
.s-cases.s-cases-vue.gradient-bg .container::before {
  display: none;
}
.s-cases.s-cases-vue.gradient-bg .container .custom-slider__num {
  color: #6e6e6e;
}
.s-cases.s-cases-vue.gradient-bg .container .s-cases__block .s-cases__block-img .look-block {
  background-color: #f7f7f7;
}
.s-cases.s-cases-vue.gradient-bg .container .s-cases__block .s-cases__block-img:hover .look-block {
  background: linear-gradient(96.51deg, #eaf0ff -0.28%, #f0e9fd 100%), #f7f7f7;
}
.s-cases.s-cases-vue.gradient-bg .container .s-cases__block .s-cases__block-img:hover .look-block .image_svg path {
  fill: #111 !important;
}
.s-cases.s-cases-download .container .s-cases__blocks-wrap .s-cases__block-img {
  margin-bottom: 24px;
  height: 500px;
}
@media only screen and (max-width: 1400px) {
  .s-cases.s-cases-download .container .s-cases__blocks-wrap .s-cases__block-img {
    height: 460px;
  }
}
@media only screen and (max-width: 1300px) {
  .s-cases.s-cases-download .container .s-cases__blocks-wrap .s-cases__block-img {
    height: 400px;
  }
}
@media only screen and (max-width: 1024px) {
  .s-cases.s-cases-download .container .s-cases__blocks-wrap .s-cases__block-img {
    height: 380px;
  }
}
@media only screen and (max-width: 991px) {
  .s-cases.s-cases-download .container .s-cases__blocks-wrap .s-cases__block-img {
    height: 350px;
  }
}
@media only screen and (max-width: 600px) {
  .s-cases.s-cases-download .container .s-cases__blocks-wrap .s-cases__block-img {
    height: 320px;
  }
}
.s-cases.s-cases-download .container .s-cases__blocks-wrap .s-cases__block-descr {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 200px;
  background-color: #261f62;
  color: #fff;
  padding: 32px 32px 80px;
  z-index: 0;
  margin: 0;
}
@media only screen and (max-width: 1380px) {
  .s-cases.s-cases-download .container .s-cases__blocks-wrap .s-cases__block-descr {
    font-size: 22px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .s-cases.s-cases-download .container .s-cases__blocks-wrap .s-cases__block-descr {
    min-height: 170px;
  }
}
.s-cases.s-cases-download .container .download-btn {
  background-color: transparent;
}
.s-cases.s-cases-download .container .download-btn span {
  font-size: 20px;
  line-height: 32px;
}
@media only screen and (max-width: 991px) {
  .s-cases.s-cases-download .container .download-btn span {
    font-size: 18px;
    line-height: 26px;
  }
}
.s-cases.s-cases-download .container .download-btn:hover {
  background-color: #5a3cc5;
}
.s-cases .container .s-cases__header {
  margin-bottom: 72px;
}
.s-cases .container .s-cases__blocks-wrap {
  margin-left: -24px;
  width: calc(100% + 48px);
}
.s-cases .container .s-cases__blocks-wrap-left .slick-track {
  margin-left: 0;
}
.s-cases .container .s-cases__block {
  height: auto;
  margin-right: 24px;
  margin-left: 24px;
  padding-left: 0;
  padding-right: 0;
}
.s-cases .container .s-cases__block .s-cases__block-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 410px;
}
@media only screen and (max-width: 1400px) {
  .s-cases .container .s-cases__block .s-cases__block-img {
    height: 360px;
  }
}
@media only screen and (max-width: 1300px) {
  .s-cases .container .s-cases__block .s-cases__block-img {
    height: 340px;
  }
}
@media only screen and (max-width: 991px) {
  .s-cases .container .s-cases__block .s-cases__block-img {
    height: 310px;
  }
}
@media only screen and (max-width: 767px) {
  .s-cases .container .s-cases__block .s-cases__block-img {
    height: 240px;
  }
}
@media only screen and (max-width: 600px) {
  .s-cases .container .s-cases__block .s-cases__block-img {
    height: 280px;
  }
}
.s-cases .container .s-cases__block .s-cases__block-img:hover .bg-img {
  transform: translateX(-50%) scale(1.1);
}
.s-cases .container .s-cases__block .s-cases__block-img:hover .look-block {
  background-color: #5a3cc5;
}
.s-cases .container .s-cases__block .s-cases__block-img:hover .look-block .image_svg path {
  fill: #fff;
}
.s-cases .container .s-cases__block .s-cases__block-img .bg-img {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
}
.s-cases .container .s-cases__block .s-cases__block-img .look-block {
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  transition: all 0.2s ease;
}
.s-cases .container .s-cases__block .s-cases__block-img .look-block .image_svg path {
  transition: all 0.2s ease;
}
.s-cases .container .s-cases__block .bottom-link:hover .bottom-link__header {
  color: #5a3cc5;
}
.s-cases .container .s-cases__block .bottom-link .bottom-link__header {
  margin-top: 32px;
  transition: color 0.2s ease;
  margin-bottom: 0;
}
.s-cases .container .s-cases__block .bottom-link .bottom-link__link {
  display: flex;
  align-items: center;
}
.s-cases .container .s-cases__block .bottom-link .bottom-link__link .learn-more__link:after {
  display: none;
}
@media only screen and (max-width: 991px) {
  .s-cases .container .s-cases__header {
    margin-bottom: 40px;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .s-cases .container .s-cases__block .bottom-link .bottom-link__link .learn-more__link:after {
    display: block;
  }
}
.s-contact-map .s-contact-map-image-modal__title {
  color: #fff;
}
.s-contact-map-tablet {
  display: none;
}
.s-contact-map h2 {
  margin-bottom: 40px;
}
.s-contact-map-logos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 64px;
}
.s-contact-map-logo {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.s-contact-map-logo img,
.s-contact-map-logo svg {
  display: inline-block;
  height: 72px;
  width: 150px;
}
.s-contact-map-content {
  display: flex;
  justify-content: space-between;
}
.s-contact-map-country {
  position: relative;
  color: #6e6e6e;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
  cursor: pointer;
}
.s-contact-map-country.active {
  color: #111;
}
.s-contact-map-country.active:before {
  background-color: #ee2b1c;
}
.s-contact-map-country:before {
  content: '';
  position: absolute;
  background-color: transparent;
  left: -24px;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transform: translateY(-50%);
  transition: background-color ease 0.15s;
}
.s-contact-map-country-block {
  left: calc(100% - 100px);
  max-width: 160px;
}
.s-contact-map-block-image {
  display: flex;
  justify-content: center;
}
.s-contact-map-block-image img,
.s-contact-map-block-image svg,
.s-contact-map-block-image object {
  display: inline-block;
  max-width: 100%;
  height: 100%;
}
.s-contact-map-image {
  position: relative;
}
.s-contact-map-image-modal {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(17,17,17,0.6);
  padding: 16px 24px;
  max-width: 300px;
  min-width: 253px;
  display: none;
}
.s-contact-map-image-modal#headquarters-country-modal {
  top: 40%;
  left: 5%;
}
.s-contact-map-image-modal#norway-country-modal {
  top: 10%;
  left: 48%;
}
.s-contact-map-image-modal#finland-country-modal {
  top: 13%;
  left: 53%;
}
.s-contact-map-image-modal#argentina-country-modal {
  top: 53%;
  left: 31%;
}
.s-contact-map-image-modal#belarus-country-modal {
  top: 13%;
  left: 54%;
}
.s-contact-map-image-modal#sweden-country-modal {
  top: 12%;
  left: 50%;
}
.s-contact-map-image-modal#denmark-country-modal {
  top: 14%;
  left: 49%;
}
.s-contact-map-image-modal#france-country-modal {
  top: 27%;
  left: 42%;
}
.s-contact-map-image-modal#germany-country-modal {
  top: 24%;
  left: 45%;
}
.s-contact-map-image-modal#ireland-country-modal {
  top: 22%;
  left: 38%;
}
.s-contact-map-image-modal#poland-country-modal {
  top: 22%;
  left: 49%;
}
.s-contact-map-image-modal#ukraine-country-modal {
  top: 17%;
  left: 56%;
}
.s-contact-map-image-modal#moldova-country-modal {
  top: 25%;
  left: 49%;
}
.s-contact-map-image-modal#georgia-country-modal {
  top: 20%;
  left: 59%;
}
.s-contact-map-image-modal#turkey-country-modal {
  top: 33%;
  left: 52%;
}
.s-contact-map-image-modal#canada-country-modal {
  top: 17%;
  left: 12%;
}
.s-contact-map-image-modal#india-country-modal {
  top: 40%;
  left: 73%;
}
.s-contact-map-image-modal.active {
  display: block;
}
.s-contact-map-image-modal h4 {
  color: #fff;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
  font-size: 22px;
}
.s-contact-map-image-modal p,
.s-contact-map-image-modal a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #fff;
  margin-bottom: 0;
}
.s-contact-map-image-modal-item {
  position: relative;
  padding-left: 26px;
}
.s-contact-map-image-modal-item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
}
.s-contact-map-image-modal-tel:before {
  background: url("https://www.altoros.com/images/uploads/phone-icon-61432226a988f.svg") center no-repeat;
}
.s-contact-map-image-modal-site:before {
  background: url("https://www.altoros.com/images/uploads/site-icon-6143221fdc78f.svg") center no-repeat;
}
.s-contact-map-image-modal-adress {
  padding-bottom: 8px;
}
.s-contact-map-item-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.s-contact-map-item-block h4 {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.35px;
  margin-bottom: 4px;
}
.s-contact-map-item-block p {
  margin-bottom: 0;
}
@media only screen and (max-width: 1399px) {
  .s-contact-map-block-image {
    margin-right: 16px;
  }
}
@media only screen and (max-width: 1219px) {
  .s-contact-map-logos {
    justify-content: flex-start;
  }
  .s-contact-map-logo {
    margin-right: 50px;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .s-contact-map-desktop {
    display: none;
  }
  .s-contact-map-tablet {
    display: block;
    margin-top: 0;
  }
  .s-contact-map-tablet .container {
    padding-top: 40px;
    padding-left: 48px;
    padding-right: 48px;
    background-color: #f7f7f7;
  }
  .s-contact-map-tablet .collapsible {
    margin-top: 0;
  }
  .s-contact-map-tablet .collapsible .collapsible-body {
    padding-top: 0;
  }
  .s-contact-map-logos {
    margin-bottom: 32px;
  }
  .s-contact-map-logo {
    margin-right: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .s-contact-map-tablet .container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .s-contact-map-content {
    flex-direction: column-reverse;
  }
  .s-contact-map-block-image {
    margin-bottom: 16px;
    width: 100%;
  }
  .s-contact-map-block-tabs {
    width: 100%;
    max-width: 100%;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact-map .collapsible-body {
    padding-left: 64px;
  }
}
@media only screen and (max-width: 599px) {
  .s-contact-map-logo {
    margin-right: 8px;
  }
  .s-contact-map-logo img {
    max-width: 120px;
  }
}
.s-clients.bg-light .container:before {
  background-color: #fff !important;
  box-shadow: 0 0 12px 4px #fff;
}
.s-clients h2 {
  margin-bottom: 56px;
}
@media only screen and (max-width: 991px) {
  .s-clients h2 {
    margin-bottom: 48px;
  }
}
.s-clients .slick-slider .slick-list {
  overflow: visible;
}
.s-clients .s-clients__blocks {
  width: auto !important;
}
.s-clients .s-clients__blocks .s-clients__block {
  margin-right: 224px;
  position: relative;
}
@media only screen and (max-width: 1220px) {
  .s-clients .s-clients__blocks .s-clients__block {
    margin-right: 96px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-clients .s-clients__blocks .s-clients__block {
    margin-right: 0;
  }
}
.s-clients .s-clients__blocks .s-clients__block .s-clients__desc {
  color: #111;
  font-size: 34px;
  letter-spacing: -0.1px;
  line-height: 48px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 991px) {
  .s-clients .s-clients__blocks .s-clients__block .s-clients__desc {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 48px;
  }
}
@media only screen and (max-width: 600px) {
  .s-clients .s-clients__blocks .s-clients__block .s-clients__desc {
    margin-bottom: 32px;
  }
}
.s-clients .s-clients__blocks .s-clients__block .s-clients__desc-light {
  color: #6e6e6e;
  font-size: 20px;
  letter-spacing: -0.1px;
  line-height: 32px;
  margin-bottom: 12px;
}
.s-clients .s-clients__blocks .s-clients__block .s-clients__desc-light span {
  display: block;
  padding: 15px 0;
  color: #111;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .s-clients .s-clients__blocks .s-clients__block .s-clients__desc-light {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 600px) {
  .s-clients .s-clients__blocks .s-clients__block .s-clients__desc-light {
    margin-bottom: 12px;
  }
}
.s-clients .s-clients__blocks .s-clients__block .s-clients__author {
  color: #111;
  letter-spacing: -0.1px;
  font-family: "circular-nova-medium";
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .s-clients .s-clients__blocks .s-clients__block .s-clients__author {
    font-size: 18px;
  }
}
.s-clients .s-clients__blocks .s-clients__block .s-clients__position {
  color: #6e6e6e;
  letter-spacing: 0.4px;
  margin-bottom: 0;
}
.s-clients .s-clients__blocks .s-clients__block .s-clients__author,
.s-clients .s-clients__blocks .s-clients__block .s-clients__position {
  font-size: 20px;
  line-height: 32px;
}
@media only screen and (max-width: 991px) {
  .s-clients .s-clients__blocks .s-clients__block .s-clients__author,
  .s-clients .s-clients__blocks .s-clients__block .s-clients__position {
    font-size: 18px;
    line-height: 24px;
  }
}
.s-clients .s-clients__blocks .s-clients__block .learn-more__link {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media only screen and (max-width: 1200px) {
  .s-clients .s-clients__blocks .s-clients__block .learn-more__link {
    right: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .s-clients .s-clients__blocks .s-clients__block .learn-more__link {
    right: 48px;
  }
}
@media only screen and (max-width: 600px) {
  .s-clients .s-clients__blocks .s-clients__block .learn-more__link {
    position: relative;
    margin-top: 24px;
    left: 0;
    right: auto;
  }
}
.s-clients .s-clients__blocks .s-clients__block .learn-more__link.toggle-block {
  cursor: pointer;
  position: relative;
  margin-top: 10px;
}
.s-clients .s-clients__blocks .s-clients__block-inner {
  max-height: 0;
  transition: max-height 0.3s ease;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .s-clients .s-clients__blocks .s-clients__block-inner {
    max-height: initial;
  }
}
.s-clients .s-clients__blocks .s-clients__block-inner.open {
  max-height: 500px;
}
.s-brands.h-header .s-brands__header {
  display: grid;
  grid-template-columns: 6fr 6fr;
}
.s-brands.h-header .s-brands__header .col {
  width: 100%;
}
.s-brands h2 {
  margin-bottom: 32px;
}
.s-brands .s-brands__header {
  margin-bottom: 40px;
}
@media only screen and (max-width: 991px) {
  .s-brands .s-brands__header {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 600px) {
  .s-brands .s-brands__header {
    margin-bottom: 16px;
  }
}
.s-brands .container-fluid .s-brands__content .slick-list {
  overflow: visible;
  padding: 0 10%;
}
@media only screen and (max-width: 991px) {
  .s-brands .container-fluid .s-brands__content .slick-list {
    padding: 0 26%;
  }
}
@media only screen and (max-width: 600px) {
  .s-brands .container-fluid .s-brands__content .slick-list {
    padding: 0 30%;
  }
}
.s-brands .container-fluid .s-brands__content .slick-track {
  display: flex;
}
.s-brands .container-fluid .s-brands__content .s-brands__img-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 240px;
  width: auto;
  margin-left: 80px;
  margin-right: 80px;
}
@media only screen and (max-width: 991px) {
  .s-brands .container-fluid .s-brands__content .s-brands__img-block {
    margin-left: 64px;
    margin-right: 64px;
  }
}
@media only screen and (max-width: 767px) {
  .s-brands .container-fluid .s-brands__content .s-brands__img-block {
    margin-left: 48px;
    margin-right: 48px;
  }
}
@media only screen and (max-width: 600px) {
  .s-brands .container-fluid .s-brands__content .s-brands__img-block {
    margin-left: 32px;
    margin-right: 32px;
    height: 60px;
    max-width: 160px;
  }
}
.s-brands .container-fluid .s-brands__content .s-brands__img-block img {
  max-height: 100%;
  max-width: 100%;
}
@media only screen and (max-width: 991px) {
  .s-brands h2 {
    margin-bottom: 20px;
    max-width: 365px;
  }
}
@media only screen and (max-width: 600px) {
  .s-brands h2 {
    margin-bottom: 24px;
  }
  .s-brands .container-fluid .s-brands__content .slick-list {
    padding: 0 20%;
  }
}
@media only screen and (max-width: 360px) {
  .s-brands .container .s-brands__content .slick-list {
    padding: 0px;
  }
}
.s-videos.s-videos-solo .container h2 {
  margin-bottom: 56px;
}
@media only screen and (max-width: 991px) {
  .s-videos.s-videos-solo .container h2 {
    margin-bottom: 37px;
  }
}
@media only screen and (max-width: 991px) {
  .s-videos.s-videos-solo .container .s-videos__content {
    margin-top: 30px;
  }
}
.s-videos.s-videos-solo .container .s-videos__content .s-videos__block .s-videos__video-wrap {
  height: 410px;
}
@media only screen and (max-width: 1400px) {
  .s-videos.s-videos-solo .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    height: 370px;
  }
}
@media only screen and (max-width: 991px) {
  .s-videos.s-videos-solo .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    max-width: 656px;
  }
}
@media only screen and (max-width: 600px) {
  .s-videos.s-videos-solo .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    height: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .s-videos.s-videos-solo .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    height: 180px;
  }
}
.s-videos .container h2 {
  max-width: 480px;
  margin-bottom: 56px;
}
@media only screen and (max-width: 991px) {
  .s-videos .container h2 {
    margin-bottom: 37px;
  }
}
.s-videos .container .s-videos__content {
  margin-left: -24px;
  width: calc(100% + 48px);
}
.s-videos .container .s-videos__content .slick-track {
  min-width: 100%;
}
.s-videos .container .s-videos__content .s-videos__block {
  position: relative;
  margin-right: 24px;
  margin-left: 24px;
  padding-left: 0;
  padding-right: 0;
}
.s-videos .container .s-videos__content .s-videos__block .s-videos__descr {
  color: #111;
  font-family: "circular-nova-medium";
  font-size: 28px;
  letter-spacing: -0.3px;
  line-height: 40px;
  margin-top: 32px;
}
.s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap {
  position: relative;
  height: 240px;
  width: 100%;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1400px) {
  .s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    height: 280px;
  }
}
@media only screen and (max-width: 991px) {
  .s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    height: 240px;
  }
}
@media only screen and (max-width: 850px) {
  .s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    height: 190px;
  }
}
@media only screen and (max-width: 767px) {
  .s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    height: 176px;
  }
}
@media only screen and (max-width: 600px) {
  .s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    height: 240px;
  }
}
@media only screen and (max-width: 400px) {
  .s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap {
    height: 200px;
  }
}
.s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap:hover .play-icon {
  background-color: #5a3cc5;
}
.s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap:hover .play-icon:after {
  border-left: 15px solid #fff;
  border-top: 7px solid #5a3cc5;
  border-bottom: 7px solid #5a3cc5;
  border-right: 7px solid #5a3cc5;
}
.s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap iframe {
  width: 100%;
  height: 100%;
}
.s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap .play-icon {
  position: absolute;
  display: block;
  width: 64px;
  height: 64px;
  background-color: #fff;
  bottom: 0;
  right: 0;
  transition: all 0.2s ease;
  z-index: 2;
}
.s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap .play-icon:after {
  content: '';
  display: block;
  width: 1px;
  height: 1px;
  top: 50%;
  left: 26px;
  transform: translate(0, -50%);
  border-left: 15px solid #6e6e6e;
  border-top: 7px solid #fff;
  border-bottom: 7px solid #fff;
  border-right: 7px solid #fff;
  transition: all 0.2s ease;
  position: absolute;
}
.s-videos .container .s-videos__content .s-videos__block .play-icon {
  position: absolute;
  display: block;
  width: 64px;
  height: 64px;
  background-color: #fff;
  transition: all 0.2s ease;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.s-videos .container .s-videos__content .s-videos__block .play-icon:after {
  content: '';
  display: block;
  width: 1px;
  height: 1px;
  top: 50%;
  left: 26px;
  transform: translate(0, -50%);
  border-left: 15px solid #6e6e6e;
  border-top: 7px solid #fff;
  border-bottom: 7px solid #fff;
  border-right: 7px solid #fff;
  transition: all 0.2s ease;
  position: absolute;
}
.s-videos .container .s-videos__content .s-videos__block:hover .play-icon {
  background-color: #5a3cc5;
}
.s-videos .container .s-videos__content .s-videos__block:hover .play-icon:after {
  border-left: 15px solid #fff;
  border-top: 7px solid #5a3cc5;
  border-bottom: 7px solid #5a3cc5;
  border-right: 7px solid #5a3cc5;
}
.s-videos-solo-modal {
  max-width: 100%;
}
.s-videos .logo-block {
  height: 144px;
  width: 256px;
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0dfe0;
}
@media only screen and (max-width: 991px) {
  .s-videos .container h2 {
    text-align: left;
    margin-bottom: 24px;
  }
  .s-videos .container .s-videos__content {
    margin-top: 0;
    width: calc(100% + 48px);
    margin-left: -24px;
  }
  .s-videos .container .s-videos__content .s-videos__block {
    margin-left: 24px;
    margin-right: 24px;
  }
  .s-videos .container .s-videos__content .s-videos__block .s-videos__descr {
    font-size: 22px;
    letter-spacing: -0.16px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .s-videos .container .s-videos__content .s-videos__block .s-videos__descr {
    margin-top: 24px;
  }
  .s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap .play-icon {
    height: 48px;
    width: 48px;
  }
  .s-videos .container .s-videos__content .s-videos__block .s-videos__video-wrap .play-icon:after {
    left: 20px;
    border-left: 11px solid #6e6e6e;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    border-right: 5px solid #fff;
  }
}
.s-contact {
  padding-top: 72px;
  margin-top: 0;
}
.s-contact.pt-200 {
  padding-top: 200px;
}
@media only screen and (max-width: 992px) {
  .s-contact.pt-200 {
    padding-top: 100px;
  }
}
.s-contact .container {
  padding: 0;
  position: relative;
}
.s-contact .container:after {
  position: absolute;
  content: '';
  display: block;
  height: 100%;
  width: 1000px;
  top: 0;
  left: -1000px;
  background-color: #fff;
}
.s-contact .container-inner {
  background-color: #fff;
  padding-top: 88px;
  padding-bottom: 88px;
  position: relative;
  padding-left: 96px;
  padding-right: 0;
  margin-right: 100px;
}
@media only screen and (max-width: 1200px) {
  .s-contact .container-inner {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media only screen and (max-width: 991px) {
  .s-contact .container-inner {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact .container-inner {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.s-contact .container-inner:after {
  content: '';
  display: block;
  width: 96px;
  height: 70%;
  background-color: #ffab30;
  position: absolute;
  bottom: 0;
  right: -96px;
}
.s-contact .container-inner .s-contact__descr {
  font-weight: 600;
  color: #111;
  font-size: 28px;
}
.s-contact .container-inner .s-contact__header {
  margin-bottom: 32px;
}
.s-contact .container-inner h2,
.s-contact .container-inner h1 {
  color: #111;
  font-family: 'circular-nova-bold';
  font-size: 52px;
  letter-spacing: -1px;
  line-height: 64px;
  margin-bottom: 30px;
}
.s-contact .container-inner .s-contact__form-wrap {
  margin-top: 56px;
}
.s-contact .container-inner .s-contact__form-wrap .btn__wrap button {
  min-width: 193px;
}
.s-contact .container-inner .s-contact__form-wrap .checkbox-wrap {
  margin: 8px 0 24px;
}
.s-contact .container-inner .s-contact__about-wrap {
  display: flex;
  flex-direction: column;
  margin-top: -160px;
}
.s-contact .container-inner .s-contact__about-wrap .s-contact__about-img {
  margin-bottom: 56px;
  width: 256px;
}
.s-contact .container-inner .s-contact__about-wrap .s-contact__about-name {
  color: #111;
  font-size: 22px;
  letter-spacing: -0.1px;
  line-height: 32px;
  font-family: 'circular-nova-bold';
  margin-bottom: 0;
}
.s-contact .container-inner .s-contact__about-wrap .s-contact__about-position {
  color: #111;
  font-size: 22px;
  letter-spacing: -0.1px;
  line-height: 32px;
  margin-bottom: 32px;
}
.s-contact .container-inner .s-contact__about-wrap .s-contact__about-tel,
.s-contact .container-inner .s-contact__about-wrap .s-contact__about-mail {
  color: #5a3cc5;
  font-size: 22px;
  letter-spacing: -0.2px;
  line-height: 32px;
  display: block;
}
.s-contact .container-inner .s-contact__about-wrap .s-contact__about-place {
  color: #111;
  font-size: 22px;
  letter-spacing: -0.2px;
  line-height: 32px;
  margin-top: 32px;
  max-width: 255px;
}
@media only screen and (max-width: 1200px) {
  .s-contact .container-inner:after {
    width: 100px;
    right: -100px;
  }
}
@media only screen and (max-width: 992px) {
  .s-contact {
    padding-top: 0;
  }
  .s-contact .container-inner {
    padding: 48px 48px;
    margin-right: 0;
  }
  .s-contact .row {
    display: flex;
    flex-wrap: wrap;
  }
  .s-contact .row .col:first-child {
    order: 2;
  }
  .s-contact .row .col:last-child {
    order: 1;
  }
  .s-contact .container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .s-contact .container .s-contact__descr {
    max-width: 515px;
  }
  .s-contact .container .s-contact__form-wrap {
    margin-top: 40px;
  }
  .s-contact .container h2 {
    font-size: 36px;
    letter-spacing: -0.95px;
    line-height: 40px;
    margin-bottom: 24px;
  }
  .s-contact .container .s-contact__about-wrap {
    margin-top: 0;
    flex-direction: row;
    align-items: center;
    margin-bottom: 64px;
  }
  .s-contact .container .s-contact__about-wrap .s-contact__about-img {
    margin-bottom: 0;
    width: 176px;
  }
  .s-contact .container .s-contact__about-wrap .s-contact__info-wrap {
    padding-left: 56px;
  }
  .s-contact .container .s-contact__about-wrap .s-contact__about-name {
    font-size: 18px;
    letter-spacing: -0.16px;
    line-height: 24px;
  }
  .s-contact .container .s-contact__about-wrap .s-contact__about-position {
    font-size: 18px;
    letter-spacing: -0.16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .s-contact .container .s-contact__about-wrap .s-contact__about-tel {
    font-size: 18px;
    letter-spacing: -0.16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .s-contact .container .s-contact__about-wrap .s-contact__about-mail {
    font-size: 18px;
    letter-spacing: -0.16px;
    line-height: 24px;
  }
  .s-contact .container .s-contact__about-wrap .s-contact__about-place {
    margin-bottom: 0;
    font-size: 18px;
    letter-spacing: -0.16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact {
    padding-top: 100px;
    margin-bottom: 96px;
  }
  .s-contact .container {
    padding-top: 0;
  }
  .s-contact .container .s-contact__form-wrap {
    margin-top: 24px;
  }
  .s-contact .container .s-contact__descr {
    margin-bottom: 24px;
  }
  .s-contact .container .s-contact__about-wrap {
    margin-top: -60px;
    flex-wrap: wrap;
    margin-bottom: 64px;
  }
  .s-contact .container .s-contact__about-wrap .s-contact__info-wrap {
    width: 100%;
    padding-left: 0;
  }
  .s-contact .container .s-contact__about-wrap .s-contact__about-img {
    width: 160px;
    margin-bottom: 32px;
  }
  .s-contact .container-inner {
    padding-right: 32px;
    padding-left: 32px;
    padding-top: 0;
  }
}
@media only screen and (max-width: 360px) {
  .s-contact .container-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.s-footer {
  padding-top: 64px;
  padding-bottom: 32px;
  background-color: #261f62;
}
.s-footer .s-footer__link h4 {
  transition: all 0.2s ease;
}
.s-footer .s-footer__link:hover h4 {
  color: #ffab30;
}
.s-footer .s-footer-wrap h3 {
  margin-top: 0;
  color: #fff;
}
.s-footer .s-footer-wrap h3.s-footer-title-yellow {
  color: #ffab30;
}
.s-footer .s-footer-wrap p {
  color: #a4a2b9;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-bottom: 5px;
  margin-right: 70px;
  min-height: 156px;
}
.s-footer .s-footer-wrap .s-footer__logo-wrap {
  margin-bottom: 24px;
}
.s-footer .s-footer-wrap .s-footer__copyright-wrap {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}
.s-footer .s-footer-wrap .s-footer__copyright-wrap .s-footer__copyright {
  color: #5b5888;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.s-footer .s-footer-wrap .s-footer__copyright-wrap .s-footer__totop-link {
  font-family: "circular-nova-medium", Sans-Serif;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  transition: all 0.2s ease;
  opacity: 0.75;
  border-radius: 5px;
  background-color: #acb6c9;
  padding: 10px 15px 10px;
}
.s-footer .s-footer-wrap .s-footer__copyright-wrap .s-footer__totop-link::after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23fff' d='M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 18px;
  height: 20px;
}
.s-footer .s-footer-wrap .s-footer__copyright-wrap .s-footer__totop-link:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 144 89' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M77.551 2.49127L141.117 67.5559C144.183 70.6941 144.183 75.7814 141.117 78.9192L133.703 86.5081C130.642 89.6407 125.682 89.6468 122.614 86.5214L71.9998 34.9566L21.3856 86.5219C18.318 89.6472 13.3575 89.6412 10.2973 86.5083L2.88322 78.9196C-0.182468 75.7814 -0.182468 70.6941 2.88322 67.5564L66.449 2.49151C69.515 -0.64622 74.4851 -0.646218 77.551 2.49127Z' fill='url(%23paint0_linear_2_3)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2_3' x1='71.9787' y1='-0.664484' x2='71.9787' y2='94.1989' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF6ED0'/%3E%3Cstop offset='0.9977' stop-color='%235445BF'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
}
.s-footer .s-footer-wrap .s-footer__top-part {
  border-bottom: 1px solid rgba(82,74,145,0.3);
  padding-bottom: 8px;
}
.s-footer .s-footer-wrap .s-footer__links-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__social-links {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  min-height: 32px;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__social-links .s-footer-social-link {
  margin-left: 22px;
  display: flex;
  align-items: center;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__social-links .s-footer-social-link:first-child {
  margin-left: 0;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__social-links .s-footer-social-link svg path {
  transition: all 0.2s ease;
  fill: rgba(255,255,255,0.35);
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__social-links .s-footer-social-link:hover svg path {
  fill: #fff;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links {
  display: flex;
  flex-direction: column;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link {
  display: block;
  margin-right: 35px;
  margin-bottom: 15px;
  line-height: 24px;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link span {
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  font-family: "circular-nova-medium", Sans-Serif;
  transition: all 0.2s ease;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link:hover span {
  color: transparent;
  background: -webkit-linear-gradient(45deg, #5445bf 0, #bb5ec9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links-right .s-footer-link__wrap {
  width: 100%;
}
.s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links-right .s-footer-link__wrap.s-footer-link__wrap_yellow span {
  color: #ffab30;
}
.s-footer .s-footer-wrap .s-footer__links-wrap-line .s-footer__main-links {
  flex-direction: row;
}
.s-footer .s-footer-wrap .s-footer__links-wrap-line .s-footer__main-links .s-footer-link__wrap {
  width: unset;
}
@media only screen and (max-width: 1299px) {
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link span {
    position: relative;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link span:before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #a4a2b9;
    width: 100%;
    bottom: -5px;
    left: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .s-footer .s-footer-wrap p {
    margin-bottom: 18px;
  }
}
@media only screen and (max-width: 992px) {
  .s-footer .s-footer-wrap .s-footer__links-wrap {
    flex-direction: column;
    align-items: baseline;
  }
  .s-footer .s-footer-wrap .s-footer__text-wrap {
    padding: 25px 0;
  }
  .s-footer .s-footer-wrap .s-footer__text-wrap p {
    min-height: auto;
    margin-right: 0;
    margin-bottom: 12px;
  }
}
@media only screen and (max-width: 991px) {
  .s-footer {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .s-footer .s-footer-wrap .s-footer__text-wrap {
    padding: 16px 0;
  }
  .s-footer .s-footer-wrap .s-footer__logo-wrap {
    margin-bottom: 32px;
  }
  .s-footer .s-footer-wrap h3 {
    margin-bottom: 16px;
  }
  .s-footer .s-footer-wrap .s-footer__copyright-wrap {
    padding-top: 32px;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap {
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__social-links .s-footer-social-link {
    transform: scale(1.1);
    margin-left: 26px;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links {
    width: 100%;
    flex-wrap: wrap;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link__wrap {
    width: auto;
    margin-bottom: 8px;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link {
    margin-bottom: 0;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link span {
    position: relative;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link span:before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #a4a2b9;
    width: 100%;
    bottom: -5px;
    left: 0;
  }
}
@media only screen and (max-width: 600px) {
  .s-footer {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .s-footer .s-footer-wrap .s-footer__copyright-wrap {
    padding-top: 48px;
  }
  .s-footer .s-footer-wrap .s-footer__copyright-wrap .s-footer__totop-link {
    display: none;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__social-links .s-footer-social-link {
    transform: scale(1.2);
    margin-left: 28px;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links {
    flex-direction: column;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link__wrap {
    margin-bottom: 12px;
  }
  .s-footer .s-footer-wrap .s-footer__links-wrap .s-footer__main-links .s-footer-link span {
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
}
